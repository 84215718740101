/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import type { DefaultTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';

import type MessagesWidgetConfig from 'views/logic/widgets/MessagesWidgetConfig';
import MessageSummaryTemplateContext from 'search/contexts/MessageSummaryTemplateContext';
import indicatorStatusColor from 'security-content/indicatorStatusColor';
import useLicenseCheck from 'license/useLicenseCheck';
import type { EventCategories, EventCategory, IndicatorTemplate } from 'security-content/types';
import { parseIfArrayValue } from 'search/messageFieldValueHelper';

type MessageFields = { [key: string]: any };

const indicatorStatusFields = ['info', 'primary', 'success', 'warning', 'danger'];
const indicatorAttributes = ['indicator', 'defaultIndicator', ...indicatorStatusFields];

const parseArray = (value) => {
  const parsedArrayValue = parseIfArrayValue(value);

  return parsedArrayValue ?? [];
};

const removeMultipleSpaces = (input: string) => input.replace(/  +/g, ' ');

const replaceFieldPlaceholder = (
  input: string,
  replaceFn: (placeholder: string, fieldName: string) => string,
) => removeMultipleSpaces(input.replace(/{(\w+)}/g, replaceFn));

const messageEventTypeCategories = (eventTypeCategories: EventCategories, messageFields: MessageFields) => {
  const { gim_event_subcategory: eventCategories } = messageFields;
  const eventCategoriesList = parseArray(eventCategories);

  if (eventCategoriesList.length === 0) {
    return [];
  }

  return eventCategoriesList.flatMap((categoryName) => get(eventTypeCategories, categoryName))
    .filter((eventCategory) => !!eventCategory) as Array<EventCategories>;
};

const messageSummary = (eventTypeCategory: EventCategory, messageFields: MessageFields) => {
  if (!eventTypeCategory) {
    return undefined;
  }

  const formatString = eventTypeCategory.summary;

  if (!formatString) {
    return undefined;
  }

  return {
    formatString: replaceFieldPlaceholder(
      formatString,
      (fieldNamePlaceholder, fieldName) => (messageFields[fieldName] !== undefined ? fieldNamePlaceholder : ''),
    ),
    summary: replaceFieldPlaceholder(
      formatString,
      (_fieldNamePlaceholder, fieldName) => (messageFields[fieldName] !== undefined ? messageFields[fieldName] : ''),
    ),
  };
};

const messageIndicatorStatus = (eventTypeCategory: EventCategory, messageFields: MessageFields) => {
  const indicatorTemplate = eventTypeCategory.indicatorTemplate ?? {};

  const indicator: IndicatorTemplate = Object.entries(eventTypeCategory)
    .filter(([key]) => indicatorAttributes.includes(key))
    .reduce((prev, [key, value]) => ({
      ...prev,
      [key]: value,
    }), indicatorTemplate);

  if (!indicator.indicator) {
    return indicator.defaultIndicator;
  }

  const fieldValue = messageFields[indicator.indicator];

  if (!fieldValue) {
    return undefined;
  }

  const matchingStatus = Object.entries(indicator)
    .filter(([key]) => indicatorStatusFields.includes(key))
    .filter(([, matchingValues]) => Array.isArray(matchingValues) && matchingValues.includes(fieldValue))
    .map(([key]) => key);

  if (matchingStatus.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(`Found more than one indicator status for field value ${fieldValue}.`);
  }

  return matchingStatus[0];
};

const messageSummaries = (eventTypeCats, messageFields, theme) => {
  return eventTypeCats.map((eventTypeCat) => {
    const summary = messageSummary(eventTypeCat, messageFields);

    if (!summary) {
      return undefined;
    }

    const indicatorStatus = messageIndicatorStatus(eventTypeCat, messageFields);
    const summaryColor = indicatorStatusColor(indicatorStatus, theme);

    return ({
      summary,
      summaryColor,
    });
  }).filter((eventTypeCat) => !!eventTypeCat);
};

const SummaryContainer = styled.div<{ $color: string }>(({ $color }) => `
  color: ${$color};
  white-space: pre-line;
`);

type Props = {
  config: MessagesWidgetConfig;
  messageFields: { [key: string]: any },
  renderMessageRow: () => React.ReactNode;
  theme: DefaultTheme,
};

const MessageSummary = ({ config, messageFields, renderMessageRow, theme }: Props) => {
  const { security: { isValid: isValidSecurityLicense } } = useLicenseCheck();
  const contextValue = React.useContext(MessageSummaryTemplateContext);
  const { isLoading, isError, eventTypeCategories } = contextValue ?? {};

  if (!config.showSummary || !isValidSecurityLicense || isError || isLoading) {
    return <>{renderMessageRow()}</>;
  }

  const eventTypeCats = messageEventTypeCategories(eventTypeCategories, messageFields);
  const summaries = messageSummaries(eventTypeCats, messageFields, theme);

  if (summaries.length === 0) {
    return <>{renderMessageRow()}</>;
  }

  return summaries.map(({ summary: { formatString, summary }, summaryColor }) => {
    return (
      <SummaryContainer $color={summaryColor} title={formatString} key={formatString}>
        {summary}
      </SummaryContainer>
    );
  });
};

export default withTheme(MessageSummary);
