import * as React from 'react';

import { Link } from 'components/common/router';
import { Alert } from 'components/bootstrap';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

const LicenseViolationNotification = () => {
  const currentUser = useCurrentUser();

  return (
    <Alert bsStyle="danger" className="has-bm">
      <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise License Violation</strong></h4>
      <p>
        At least one term of your Graylog Enterprise license has been violated.{' '}
        {isPermitted(currentUser.permissions, 'licenses:create') ? (
          <span>
            Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
            more information or contact your Graylog account manager.
          </span>
        ) : (
          <span>Contact your Graylog account manager for more information.</span>
        )}
      </p>
    </Alert>
  );
};

export default LicenseViolationNotification;
