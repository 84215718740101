import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash';

import type { SelectedSearchFilter as SelectedSearchFilterType } from 'search-filter/types';
import { SplitButton } from 'components/bootstrap';
import { Icon, TextOverflowEllipsis } from 'components/common';
import SearchFilterActionsPopover from 'search-filter/components/search-filter-bar/SearchFilterActionsPopover';
import { REFERENCED_USER_SEARCH_FILTER_TYPE } from 'search-filter/constants';
import SearchFilterEditPopover from 'search-filter/components/search-filter-bar/SearchFilterEditPopover';
import UserFilterShareModal from 'search-filter/components/UserFilterShareModal';
import useSearchFiltersFormState from 'search-filter/hooks/useSearchFiltersFormState';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

import useSearchFiltersFormActions from '../../hooks/useSearchFiltersFormActions';

type Props = {
  filter: SelectedSearchFilterType,
}

const HelperSpan = styled.span`
  font-size: 70%;
  text-align: right;
  position: absolute;
  bottom: -1.3em;
  right: 0;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
`;

const InlineDiv = styled.div<{ $isDisabled: boolean }>(({ $isDisabled, theme }) => css`
  text-decoration:  ${$isDisabled ? 'line-through' : 'initial'};
  display: grid;
  grid-gap: 8px;
  align-items: center;
  grid-template-columns: auto auto;
  position: relative;
  color: ${theme.colors.global.textDefault};
`);

const ButtonTitle = styled.div<{ $isDisabled: boolean }>(({ $isDisabled }) => css`
  text-decoration:  ${$isDisabled ? 'line-through' : 'initial'};
`);

const StyledSplitButton = styled(SplitButton)`
  && {
    padding-right: 0;
  }

  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
`;

const StyledTextOverflowEllipsis = styled(TextOverflowEllipsis)`
  max-width: 125px;
`;

const FilterReferenceLabel = ({ filterType, filterId }: { filterType: string, filterId: string }) => {
  const currentUser = useCurrentUser();

  if (filterType !== REFERENCED_USER_SEARCH_FILTER_TYPE) {
    return null;
  }

  const userIsOwner = isPermitted(currentUser.permissions, [`search_filters:delete:${filterId}`]);

  return (
    <HelperSpan>
      {userIsOwner ? 'from "My Filters"' : 'shared with me'}
    </HelperSpan>
  );
};

const SelectedSearchFilter = ({
  filter,
  filter: { id, title, queryString, type, frontendId, negation, description, disabled },
}: Props) => {
  const { updateFilter } = useSearchFiltersFormActions();
  const filterContainerRef = useRef();
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showEditPopover, setShowEditPopover] = useState(false);
  const [showSharePopover, setShowSharePopover] = useState(false);
  const filterTitle = title || queryString;
  const hoverTitle = `${title ? `Title: ${title}. ` : ''}Query: ${queryString}.${description ? ` Description: ${description}.` : ''}`;
  const selectedFilters = useSearchFiltersFormState();
  const toggleShowFilterMenu = useCallback(() => {
    setShowFilterMenu((cur) => !cur);
  }, [setShowFilterMenu]);
  const toggleDisableFilter = useCallback(() => {
    updateFilter(frontendId, { ...filter, disabled: !disabled });
  }, [disabled, filter, frontendId, updateFilter]);

  const toggleShowSharePopover = useCallback(() => {
    setShowSharePopover((cur) => !cur);

    if (!showSharePopover) {
      toggleShowFilterMenu();
    }
  }, [showSharePopover, toggleShowFilterMenu]);

  const toggleShowEditPopover = useCallback(() => {
    setShowEditPopover((cur) => !cur);

    if (!showEditPopover) {
      toggleShowFilterMenu();
    }
  }, [showEditPopover, toggleShowFilterMenu]);

  const updateFilterInCurrentSearch = useCallback((newFilter: SelectedSearchFilterType) => {
    const previousFilter = selectedFilters.get(newFilter.frontendId);

    if (!isEqual(newFilter, previousFilter)) {
      updateFilter(newFilter.frontendId, newFilter);
    }

    toggleShowEditPopover();
  }, [selectedFilters, toggleShowEditPopover, updateFilter]);

  return (
    <div data-testid={`selected-filter-${frontendId}`}>
      <StyledSplitButton bsSize="xsmall"
                         bsStyle="link"
                         $isDisabled={disabled}
                         ref={filterContainerRef}
                         onClick={toggleDisableFilter}
                         rootCloseEvent="click"
                         id={`filter-menu-popover-split-button-${frontendId}`}
                         onToggle={toggleShowFilterMenu}
                         open={showFilterMenu}
                         defaultOpen={false}
                         toggleLabel="Show search filter actions"
                         title={(
                           <InlineDiv>
                             <Icon name={disabled ? 'square' : 'square-check'} />
                             <ButtonTitle $isDisabled={disabled} data-testid="selected-filter-title">
                               <StyledTextOverflowEllipsis titleOverride={hoverTitle}>
                                 {negation && <b>NOT{' '}</b>}
                                 {filterTitle}
                               </StyledTextOverflowEllipsis>
                             </ButtonTitle>
                             <FilterReferenceLabel filterType={type} filterId={id} />
                           </InlineDiv>
                         )}>
        {showFilterMenu && (
          <SearchFilterActionsPopover filter={filter}
                                      onEditClick={toggleShowEditPopover}
                                      onShareClick={toggleShowSharePopover}
                                      onItemClick={toggleShowFilterMenu} />
        )}
      </StyledSplitButton>
      {showEditPopover && (
        <SearchFilterEditPopover onSave={updateFilterInCurrentSearch}
                                 onCancel={toggleShowEditPopover}
                                 target={filterContainerRef.current}
                                 filter={filter}
                                 show />
      )}
      {showSharePopover && <UserFilterShareModal filter={filter} toggleShareModalOpen={toggleShowSharePopover} />}
    </div>
  );
};

export default SelectedSearchFilter;
