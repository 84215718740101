import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import Input from 'components/bootstrap/Input';
import Select from 'components/common/Select';
import Spinner from 'components/common/Spinner';
import { defaultCompare } from 'logic/DefaultCompare';
import type { ValidationResult } from 'enterprise/parameters/components/ParameterDeclarationForm';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import { Properties } from 'views/logic/fieldtypes/FieldType';
import { FieldSource } from 'enterprise/parameters/components/option/OptionSource';
import type { OptionSourceEditComponentProps } from 'enterprise/parameters/components/option/types';

const retrieveFieldNames = async (): Promise<FieldTypeMapping[]> => fetch('GET', qualifyUrl('/views/fields'));

type Field = {
  name: string,
  disabled?: boolean,
};

const useFieldNames = (isDisabled: ((fieldType: FieldTypeMapping) => boolean) = () => true) => {
  const [fieldNames, setFieldNames] = useState<Field[] | undefined>(undefined);

  useEffect(() => {
    retrieveFieldNames()
      .then((fields) => fields.map((field): Field => ({ name: field.name, disabled: isDisabled(field) })))
      .then(setFieldNames);
  }, [isDisabled]);

  return fieldNames;
};

const fieldIsDisabled = ({ name, type: { type, properties } }: FieldTypeMapping): boolean => (name !== 'source')
  && (type !== 'string' || !properties.includes(Properties.Enumerable))
  && !properties.includes(Properties.Numeric);

const notInternal = ({ name }) => !name.startsWith('gl2_');

const sortFields = (field1: Field, field2: Field) => defaultCompare(field1.name, field2.name);

const createOption = ({ name, disabled }: Field) => ({ key: name, value: name, disabled: disabled });

const FieldSourceForm = ({ idx, source: { field, limit }, onChange, onValidate, validationState }: OptionSourceEditComponentProps<FieldSource>) => {
  const fieldNames = useFieldNames(fieldIsDisabled);
  const options = useMemo(() => (
    fieldNames
      ? fieldNames.filter(notInternal).sort(sortFields).map(createOption)
      : undefined
  ), [fieldNames]);

  useEffect(() => {
    const fieldValidationState: ValidationResult = (field !== undefined && field !== '')
      ? ['success']
      : ['error', 'Please select field'];
    const limitValidationState: ValidationResult = (limit !== undefined && limit > 0)
      ? ['success']
      : ['error', 'Limit must be a positive number.'];

    onValidate(idx, {
      'source.field': fieldValidationState,
      'source.limit': limitValidationState,
    });
  }, [field, idx, limit, onValidate]);

  useEffect(() => () => {
    onValidate(idx, { 'source.field': ['success'], 'source.limit': ['success'] });
  }, [idx, onValidate]);

  const _onChangeField = useCallback((newField: string) => onChange(FieldSource.create(newField, limit)), [limit, onChange]);
  const _onChangeLimit = useCallback((event: React.ChangeEvent<HTMLInputElement>) => onChange(
    FieldSource.create(field, Number.parseInt(event.target.value, 10)),
  ), [field, onChange]);

  return options
    ? (
      <>
        <Input id={`field-${idx}`}
               name="field"
               label="Field"
               bsStyle={validationState?.['source.field']?.[0]}
               help={validationState?.['source.field']?.[1]}>
          <Select placeholder="Select a field"
                  inputProps={{ 'aria-label': 'Select a field' }}
                  displayKey="key"
                  inputId="parameter-value-input"
                  onChange={_onChangeField}
                  value={field}
                  options={options} />
        </Input>
        <Input id={`limit-${idx}`}
               name="limit"
               label="Limit"
               type="number"
               value={limit}
               onChange={_onChangeLimit}
               bsStyle={validationState?.['source.limit']?.[0]}
               help={validationState?.['source.limit']?.[1]} />
      </>
    )
    : <Spinner delay={200} />;
};

export default FieldSourceForm;
