import React, { useCallback, useRef } from 'react';

import type { SelectedSearchFilter } from 'search-filter/types';
import SearchFilterCreatePopover from 'search-filter/components/search-filter-bar/SearchFilterCreatePopover';
import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import useSearchFiltersFormActions from 'search-filter/hooks/useSearchFiltersFormActions';

type Props = {
  showPopover: boolean,
  toggleShowPopover: () => void,
}

const SearchFilterCreate = ({ showPopover, toggleShowPopover }: Props) => {
  const addButtonRef = useRef();
  const { addFilter } = useSearchFiltersFormActions();

  const _onSave = useCallback((searchFilter: SelectedSearchFilter) => {
    addFilter(searchFilter);
    toggleShowPopover();
  }, [addFilter, toggleShowPopover]);

  return (
    <>
      <Button ref={addButtonRef} onClick={toggleShowPopover} title="Create new filter" bsSize="small">
        <Icon name="plus" />
      </Button>
      {showPopover && (
        <SearchFilterCreatePopover onSubmit={_onSave}
                                   onCancel={toggleShowPopover}
                                   target={addButtonRef.current}
                                   show />
      )}
    </>
  );
};

export default SearchFilterCreate;
