import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';

import type { Notification } from 'theme/types';
import FormikInput from 'components/common/FormikInput';
import Select from 'components/common/Select';
import { Row, Col, Modal, Panel, ControlLabel } from 'components/bootstrap';
import { ModalSubmit } from 'components/common';

import NotificationExample from './NotificationExample';
import NotificationHelp from './Help';

interface Props {
  show: boolean,
  toggleShowModal: () => void,
  onSubmit: (notificationValues: Notification) => Promise<void | Notification>,
  initialValues: Notification,
  submitButtonText: string,
  submitLoadingText: string,
}

const StyledRow = styled(Row)`
  margin-bottom: 0.5rem;

  .form-group {
    .title-label {
      width: 100%;
    }
    
    #longMessage {
      height: 8rem;
    }
  }
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  
  .checkbox {
    margin: 0;
  }
`;

const ExampleHeader = styled.h4`
  margin-bottom: 0.75rem;
`;

const BooleanLabel = styled.span(({ theme }) => css`
  span[role='button'] {
    margin-left: 6px;
    color: ${theme.colors.variant.light.default};
  }
`);

const LargeFormikInput = styled(FormikInput)(({ theme }) => css`
  font-size: ${theme.fonts.size.h4};
  margin: 0 21px 0 0;
  
  &.checkbox {
    > label {
      display: flex;
      padding-left: ${theme.fonts.size.h1};

      > input[type="checkbox"] {
        width: ${theme.fonts.size.h4};
        height: ${theme.fonts.size.h4};
        margin-left: -${theme.fonts.size.h1};
      }
    }
  }
`);

const NewNotice = styled.span(({ theme }) => css`
  color: ${theme.colors.variant.dark.default};
  font-style: italic;
  margin-right: ${theme.spacings.md};
`);

const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  align-items: center;
  
  button.close {
    order: 3;
  }
  
  .modal-title {
    flex: 1;
  }
  
  .form-group {
    margin: 0;
  }
`;

const BehaviorFormikInput = styled(FormikInput)`
  margin: 0;
`;

const BehaviorHeading = styled(Panel.Heading)`
  padding: 6px 9px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalForm = ({ show, toggleShowModal, onSubmit, initialValues, submitButtonText, submitLoadingText }: Props) => {
  return (
    <Modal show={show} onHide={toggleShowModal} bsSize="large" aria-modal="true" aria-labelledby="dialog_label">
      <Formik onSubmit={onSubmit} initialValues={initialValues}>

        {({ isSubmitting, values, setFieldValue }) => {
          const changeColorVariant = (variant) => {
            setFieldValue('variant', variant);
          };

          return (
            <Form>
              <StyledModalHeader closeButton>
                <Modal.Title id="dialog_label">Notification Message</Modal.Title>
                <LargeFormikInput type="checkbox"
                                  name="isActive"
                                  id="is-active"
                                  label="Active" />
              </StyledModalHeader>

              <Modal.Body>
                <Row>
                  <Col sm={7}>
                    <StyledRow>
                      <Col sm={12}>
                        <FormikInput name="title"
                                     id="title"
                                     required
                                     labelClassName="title-label"
                                     label={(
                                       <Title>
                                         <span>Title</span>
                                         <FormikInput type="checkbox"
                                                      name="hiddenTitle"
                                                      id="hidden-title"
                                                      label="Hide Title" />
                                       </Title>
                                   )} />
                      </Col>
                    </StyledRow>

                    <StyledRow>
                      <Col sm={12}>
                        <FormikInput label="Short Message"
                                     name="shortMessage"
                                     id="short-message"
                                     help="Message that will always appear."
                                     required />
                      </Col>
                    </StyledRow>
                  </Col>

                  <Col sm={3} smOffset={2}>
                    <Row>
                      <Col sm={12} />
                    </Row>
                    <Panel>
                      <BehaviorHeading>
                        <Panel.Title>Behavior</Panel.Title>
                      </BehaviorHeading>
                      <Panel.Body>
                        <div>
                          <Row>
                            <Col sm={12}>
                              <BehaviorFormikInput type="checkbox"
                                                   name="isDismissible"
                                                   id="is-dismissible"
                                                   label={(
                                                     <BooleanLabel>
                                                       <span>Dismissible</span>
                                                       <NotificationHelp message="Use can dismiss from their view during their current session" />
                                                     </BooleanLabel>
                                           )} />
                            </Col>
                            <Col sm={12}>
                              <BehaviorFormikInput type="checkbox"
                                                   name="atLogin"
                                                   id="at-login"
                                                   label={(
                                                     <BooleanLabel>
                                                       <span>Visible At Login</span>
                                                       <NotificationHelp message="Visible above the Login fields" />
                                                     </BooleanLabel>
                                           )} />
                            </Col>
                            <Col sm={12}>
                              <BehaviorFormikInput type="checkbox"
                                                   name="isGlobal"
                                                   id="is-global"
                                                   label={(
                                                     <BooleanLabel>
                                                       <span>Visible Globally</span>
                                                       <NotificationHelp message="Visible above content on every page" />
                                                     </BooleanLabel>
                                           )} />
                            </Col>
                          </Row>
                        </div>
                      </Panel.Body>
                    </Panel>
                  </Col>
                </Row>

                <StyledRow>
                  <Col sm={12}>
                    <FormikInput label="Read More Message"
                                 name="longMessage"
                                 type="textarea"
                                 help={'(optional) Message content of "Read More".'}
                                 id="long-message" />
                  </Col>
                </StyledRow>

                <StyledRow>
                  <Col sm={6}>
                    <ControlLabel htmlFor="variant">Color Variant</ControlLabel>
                    <Select clearable={false}
                            name="variant"
                            id="variant"
                            options={[
                              { label: 'Default', value: 'default' },
                              { label: 'Error', value: 'danger' },
                              { label: 'Informative', value: 'info' },
                              { label: 'Primary', value: 'primary' },
                              { label: 'Success', value: 'success' },
                              { label: 'Warning', value: 'warning' },
                            ]}
                            onChange={changeColorVariant}
                            value={values.variant} />
                  </Col>
                </StyledRow>

                <Row>
                  <Col sm={12}><hr /></Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <ExampleHeader>Example</ExampleHeader>
                    <NotificationExample title={values.title}
                                         bsStyle={values.variant}
                                         shortMessage={values.shortMessage}
                                         longMessage={values.longMessage}
                                         hiddenTitle={values.hiddenTitle}
                                         dismissible={values.isDismissible} />
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Footer>
                  <NewNotice>Public Notifications may take a few moments to appear for your users.</NewNotice>
                  <ModalSubmit onCancel={toggleShowModal}
                               isSubmitting={isSubmitting}
                               isAsyncSubmit
                               submitButtonText={submitButtonText}
                               submitLoadingText={submitLoadingText} />
                </Footer>
              </Modal.Footer>
            </Form>
          );
        }}

      </Formik>
    </Modal>
  );
};

ModalForm.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  submitLoadingText: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    title: PropTypes.string,
    shortMessage: PropTypes.string,
    longMessage: PropTypes.string,
    isActive: PropTypes.bool,
    isDismissible: PropTypes.bool,
    atLogin: PropTypes.bool,
    isGlobal: PropTypes.bool,
    variant: PropTypes.string,
    hiddenTitle: PropTypes.bool,
  }),
};

ModalForm.defaultProps = {
  initialValues: {
    title: '',
    shortMessage: '',
    longMessage: '',
    isActive: true,
    isDismissible: false,
    atLogin: false,
    isGlobal: false,
    variant: 'default',
    hiddenTitle: false,
  },
};

export default ModalForm;
