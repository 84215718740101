import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Select } from 'components/common';
import Spinner from 'components/common/Spinner';
import { useStore } from 'stores/connect';
import { defaultCompare } from 'logic/DefaultCompare';
import { Button, ControlLabel, FormGroup, Row, Col } from 'components/bootstrap';
import ForwarderInputsStore, { ForwarderInputsActions } from 'forwarder/stores/ForwarderInputsStore';
import InputProfilesStore, { InputProfilesActions } from 'forwarder/stores/InputProfilesStore';
import type { Input, InputProfile } from 'forwarder/Types';

const PLACEHOLDER = 'placeholder';

const LabelPlaceholder = styled.div`
  height: 25px; // label takes 20px + 5px margin
`;

type Props = {
  preselectedInputId?: string,
  label: string,
  title: string,
  onLoadMessage: (inputId: string) => void,
  loadButtonDisabled: boolean,
  autoLoadMessage: boolean,
};

// FIXME: Input preselection does not work, we have no way of finding an Input Profile from its Input.
const ForwarderInputDropdown = ({ label, title, onLoadMessage, loadButtonDisabled, autoLoadMessage }: Props) => {
  const { all: inputProfiles } = useStore(InputProfilesStore);
  const [selectedInput, setSelectedInput] = useState(PLACEHOLDER);
  const [selectedInputProfileId, setSelectedInputProfileId] = useState<string | undefined>();
  const { forwarderInputs } = useStore(ForwarderInputsStore);

  const _sortByTitle = (item1: InputProfile | Input, item2: InputProfile | Input) => defaultCompare(item1.title, item2.title);

  const _formatListItem = (item: InputProfile | Input) => {
    return { value: item.id, label: item.title };
  };

  const _onSelectedForwarderChange = (inputProfileId: string) => {
    const inputProfile = inputProfiles
      && inputProfiles.find((inputProfileItem) => inputProfileItem.id === inputProfileId);

    if (inputProfile) {
      setSelectedInputProfileId(inputProfile.id);
    }
  };

  const _onSelectedInputChange = (inputId: string) => {
    if (autoLoadMessage) {
      onLoadMessage(inputId);
    }

    setSelectedInput(inputId);
  };

  const _onLoadMessage = () => {
    onLoadMessage(selectedInput);
  };

  useEffect(() => {
    InputProfilesActions.listAll();
  }, []);

  useEffect(() => {
    if (selectedInputProfileId) {
      ForwarderInputsActions.list({ inputProfileId: selectedInputProfileId });
    }
  }, [selectedInputProfileId]);

  if (!inputProfiles) {
    return <Spinner />;
  }

  const inputProfileOptions = inputProfiles
    .sort(_sortByTitle)
    .map(_formatListItem);
  const inputs = forwarderInputs[selectedInputProfileId];
  const inputOptions = (inputs && inputs.sort(_sortByTitle).map(_formatListItem));

  return (
    <Row>
      <Col md={autoLoadMessage ? 6 : 5}>
        <FormGroup controlId="inputProfileSelect">
          {label && <ControlLabel>{label}</ControlLabel>}
          <Select inputId="inputProfileSelect"
                  name="inputProfileSelect"
                  placeholder="Select Input Profile"
                  options={inputProfileOptions}
                  matchProp="label"
                  aria-label="select input profile"
                  onChange={_onSelectedForwarderChange}
                  value={undefined} />
        </FormGroup>
      </Col>
      <Col md={autoLoadMessage ? 6 : 5}>
        <FormGroup controlId="input">
          {label && <LabelPlaceholder />}
          <Select id="input"
                  name="input"
                  placeholder={(selectedInputProfileId && !inputs) ? 'Loading...' : 'Select Input'}
                  disabled={!inputs}
                  options={inputOptions || []}
                  matchProp="label"
                  aria-label="select input"
                  onChange={_onSelectedInputChange}
                  value={undefined} />
        </FormGroup>
      </Col>
      {!autoLoadMessage && (
        <Col md={1}>
          <Button bsStyle="info"
                  disabled={loadButtonDisabled || selectedInput === PLACEHOLDER}
                  onClick={_onLoadMessage}>
            {title}
          </Button>
        </Col>
      )}
    </Row>
  );
};

ForwarderInputDropdown.propTypes = {
  autoLoadMessage: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  preselectedInputId: PropTypes.string,
  onLoadMessage: PropTypes.func.isRequired,
  loadButtonDisabled: PropTypes.bool,
};

ForwarderInputDropdown.defaultProps = {
  preselectedInputId: undefined,
  autoLoadMessage: false,
  label: undefined,
  title: '',
  loadButtonDisabled: false,
};

export default ForwarderInputDropdown;
