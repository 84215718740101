import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonToolbar, Table } from 'components/bootstrap';

import type { Input, ForwarderInputTypes } from '../Types';
import { ForwarderInputPropType } from '../Types';

type Props = {
  inputs: Array<Input>,
  inputTypes: ForwarderInputTypes,
  onInputDelete: (input: Input) => void,
  onInputEdit: (input: Input) => void,
  withActions?: boolean,
};

const InputsTable = ({ inputs, inputTypes, onInputDelete, onInputEdit, withActions }: Props) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>Title</th>
          <th>Type</th>
          {withActions && <th>&nbsp;</th>}
        </tr>
      </thead>
      <tbody>
        {inputs.map((input) => {
          const { title, type } = input;

          return (
            <tr key={title}>
              <td>{title}</td>
              <td>{inputTypes[type] ? inputTypes[type] : type}</td>
              {withActions && (
                <td>
                  <ButtonToolbar>
                    <Button bsStyle="primary" bsSize="xs" onClick={() => onInputDelete(input)}>Delete</Button>
                    <Button bsStyle="success" bsSize="xs" onClick={() => onInputEdit(input)}>Edit</Button>
                  </ButtonToolbar>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

InputsTable.propTypes = {
  inputs: PropTypes.arrayOf(ForwarderInputPropType).isRequired,
  inputTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  onInputDelete: PropTypes.func,
  onInputEdit: PropTypes.func,
  withActions: PropTypes.bool,
};

InputsTable.defaultProps = {
  onInputDelete: () => {},
  onInputEdit: () => {},
  withActions: false,
};

export default InputsTable;
