import type { BulkActions } from 'security-app/types';

const API_PLUGIN_ROUTE = '/plugins/org.graylog.plugins';

const EnterpriseApiRoutes = {
  Illuminate: {
    listPacks: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
    updatePacks: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
    listBundles: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles` }),
    updateBundle: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}` }),
    deleteBundle: (version: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${version}` }),
    uploadBundle: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/upload` }),
    status: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/status` }),
  },
  SecurityApp: {
    anomalyConfiguration: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config` }),
    updateAnomalyConfiguration: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/update_status` }),
    fetchDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config/${id}` }),
    updateDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config/${id}` }),
    createDetectorDefinition: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config` }),
    deleteDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config/${id}` }),
    createSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules` }),
    validateSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/validate` }),
    allSigmaRules: (options: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules?${options}` }),
    oneSigmaRule: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/${id}` }),
    importRepoSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/import` }),
    toggleSigmaRule: (
      id: string,
      action: 'enable' | 'disable',
    ) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/${id}/${action}` }),
    sigmaBulkOperation: (action: BulkActions) => (
      { url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/bulk/${action}` }
    ),
  },
  ContentUI: {
    eventTypeCategory: () => ({ url: `${API_PLUGIN_ROUTE}.contentui/event_type_category/ui_mapping` }),
    externalValueActions: () => ({ url: `${API_PLUGIN_ROUTE}.contentui/external_actions/ui_mapping` }),
  },
};

export default EnterpriseApiRoutes;
