import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';

import { HelpBlock, Row } from 'components/bootstrap';
import TimeRangeInput from 'views/components/searchbar/TimeRangeInput';
import ExportSettingsContext from 'views/components/ExportSettingsContext';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import { useStore } from 'stores/connect';
import { SearchConfigStore } from 'views/stores/SearchConfigStore';
import type { DateTimeFormats } from 'util/DateTime';
import { adjustFormat, toUTCFromTz } from 'util/DateTime';
import useUserDateTime from 'hooks/useUserDateTime';

declare module 'views/components/ExportSettingsContext' {
  interface ExportSettings {
    timerange?: AbsoluteTimeRange;
  }
}

const toPickerRange = (timerange: AbsoluteTimeRange, toUserTime: (dateTime: string, format: DateTimeFormats) => string) => (timerange ? ({
  type: 'absolute',
  from: toUserTime(timerange.from, 'complete'),
  to: toUserTime(timerange.to, 'complete'),
}) : undefined);

const fromPickerRange = (timerange: AbsoluteTimeRange, userTimezone: string) => (timerange ? ({
  type: 'absolute',
  from: adjustFormat(toUTCFromTz(timerange.from, userTimezone), 'internal'),
  to: adjustFormat(toUTCFromTz(timerange.to, userTimezone), 'internal'),
}) : null);

const LogViewExportSettings = () => {
  const { settings } = useContext(ExportSettingsContext);
  const { formatTime, userTimezone } = useUserDateTime();
  const { searchesClusterConfig } = useStore(SearchConfigStore);
  const { setFieldValue } = useFormikContext();
  const limitDuration = moment.duration(searchesClusterConfig?.query_time_range_limit).asSeconds() ?? 0;

  useEffect(() => {
    setFieldValue('customSettings.timerange', settings?.timerange);
  }, [setFieldValue, settings]);

  return (
    <Row>
      <Field name="customSettings.timerange">
        {({ field: { name, value, onChange } }) => {
          const currentTimeRange = toPickerRange(value, formatTime);
          const setCurrentTimeRange = (newTimeRange) => onChange({ target: { name, value: fromPickerRange(newTimeRange, userTimezone) } });

          return (
            <>
              <label htmlFor={name}>Time Range</label>
              <TimeRangeInput value={currentTimeRange}
                              onChange={setCurrentTimeRange}
                              validTypes={['absolute']}
                              showPresetDropdown={false}
                              limitDuration={limitDuration} />
              <HelpBlock>
                This is the time range which is used for the export. It defaults to the range you are currently seeing
                in the widget.
                If you want it to include more/less messages, you can adjust by clicking on the button/summary.
              </HelpBlock>
            </>
          );
        }}
      </Field>
    </Row>
  );
};

export default LogViewExportSettings;
