import * as React from 'react';
import styled from 'styled-components';

import { InputGroup } from 'components/bootstrap';
import type LookupTableParameter from 'views/logic/parameters/LookupTableParameter';

import type { ParameterInputComponentProps } from '../ParameterTypes';

const TableInfo = styled(InputGroup.Addon)(({ theme }) => `
  color: ${theme.colors.variant.dark.default};
  width: auto;
  text-align: left;
`);

type Props = ParameterInputComponentProps<LookupTableParameter>;

const LookupTableParameterPreview = ({ parameter: { key, lookupTable } }: Props) => {
  return <TableInfo>Lookup Table: {lookupTable} &ndash; {key}</TableInfo>;
};

export default LookupTableParameterPreview;
