import React from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';

import Routes from 'routing/Routes';
import type { ActionComponentProps } from 'views/components/actions/ActionHandler';

import SelectViewWithParameter from './SelectViewWithParameter';

class ExecuteViewWithValue extends React.Component<ActionComponentProps> {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
  };

  _onSubmit = (selectedView?: string, selectedParameter?: string) => {
    this.props.onClose();

    if (selectedView && selectedParameter) {
      const uri = new URI(Routes.VIEWS.VIEWID(selectedView)).search({ [selectedParameter]: this.props.value });
      const newWindow = window.open(uri.toString(), '_blank');
      newWindow.focus();
    }
  };

  render() {
    return <SelectViewWithParameter onSubmit={this._onSubmit} />;
  }
}

export default ExecuteViewWithValue;
