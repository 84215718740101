import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 6em;
  border-bottom: 2px solid ${(props: any) => props.theme.colors.gray[80]};
`;

export default Header;
