import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import UserNotification from 'util/UserNotification';
import type { HttpGetAction, WatchlistAction } from 'security-content/types';

function getExternalActions(): Promise<Record<string, HttpGetAction | WatchlistAction>> {
  return fetch('get', qualifyUrl(EnterpriseApiRoutes.ContentUI.externalValueActions().url));
}

type UseExternalActionsQueryParams = {
  isLoadingSecurityLicense: boolean;
  isValidSecurityLicense: boolean;
}

export default function useExternalActionsQuery({ isLoadingSecurityLicense, isValidSecurityLicense }: UseExternalActionsQueryParams) {
  const { isFetching, isError, data, error } = useQuery(['externalActions'], getExternalActions, {
    onError: (fetchError: Error) => {
      UserNotification.error(`Error fetching external actions: ${fetchError.message}`);
    },
    enabled: !isLoadingSecurityLicense && isValidSecurityLicense,
  });

  return {
    isFetching,
    isError,
    data,
    error,
  };
}
