import * as React from 'react';
import type { SyntheticEvent } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/common/router';
import { Alert, Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import Routes from 'routing/Routes';

type Props = {
  expiresIn: string
  enableReminderButton?: boolean,
  onReminderClick: (event: SyntheticEvent<HTMLButtonElement>) => void
}

const LicenseExpirationWarningNotification = ({ expiresIn, enableReminderButton, onReminderClick }: Props) => (
  <Alert bsStyle="warning" className="has-bm">
    <p>
      <strong>Graylog Enterprise License Expiration Warning:</strong>
      &nbsp;
      Your Graylog Enterprise license will expire in <strong>{expiresIn}</strong>.
      Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
      more information or contact your Graylog account manager.
      {enableReminderButton
          && (
          <Button bsSize="xsmall" className="pull-right" onClick={onReminderClick}>
            <Icon name="times" /> Remind me later
          </Button>
          )}
    </p>
  </Alert>
);

LicenseExpirationWarningNotification.propTypes = {
  expiresIn: PropTypes.string.isRequired,
  onReminderClick: PropTypes.func.isRequired,
  enableReminderButton: PropTypes.bool,
};

LicenseExpirationWarningNotification.defaultProps = {
  enableReminderButton: true,
};

export default LicenseExpirationWarningNotification;
