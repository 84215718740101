import * as React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/common/router';
import Spinner from 'components/common/Spinner';
import { Alert, Col, Row } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';
import withHideOnCloud from 'util/conditional/withHideOnCloud';
import type { LicenseStatus } from 'archive/types';

import { isValidLicense } from '../../logic/license';

const NoValidLicense = ({ message }: { message: string | undefined }) => (
  <Alert bsStyle="danger">
    <h4 style={{ fontWeight: 'bold', marginBottom: 5 }}>Reporting is disabled</h4>
    <p>
      {message ?? 'Report scheduling is disabled because there is no valid license.'}
    </p>
    <IfPermitted permissions="licenses:create">
      <p>
        See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
      </p>
    </IfPermitted>
  </Alert>
);

type Props = {
  colWidth?: number,
  licenseStatus: LicenseStatus,
  message: string | undefined,
  rowClass?: string,
  summary?: boolean,
}

const ReportLicenseStatus = ({ licenseStatus, summary, rowClass, colWidth, message }: Props) => {
  if (isValidLicense(licenseStatus)) {
    return null;
  }

  if (licenseStatus?.loading) {
    return <Spinner />;
  }

  if (summary) {
    return <NoValidLicense message={message} />;
  }

  return (
    <Row className={rowClass}>
      <Col md={colWidth}>
        <NoValidLicense message={message} />
      </Col>
    </Row>
  );
};

ReportLicenseStatus.propTypes = {
  message: PropTypes.string,
  colWidth: PropTypes.number,
  rowClass: PropTypes.string,
  summary: PropTypes.bool,
};

ReportLicenseStatus.defaultProps = {
  colWidth: 12,
  rowClass: 'content',
  message: undefined,
  summary: false,
};

export default withHideOnCloud(ReportLicenseStatus);
