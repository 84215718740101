import * as React from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';

import connect from 'stores/connect';
import ParameterDeclarationForm from 'enterprise/parameters/components/ParameterDeclarationForm';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import {
  newParameterState,
  SearchParameterActions,
  SearchParameterStore,
} from 'enterprise/parameters/stores/SearchParameterStore';

type Props = {
  existingParameters: ParameterMap,
  onClose: () => void,
};

class CreateParameterDialog extends React.Component<Props> {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  onSave = (newParameters: ParameterMap) => {
    const { onClose } = this.props;

    return SearchParameterActions.declare(newParameters).then(onClose);
  };

  render() {
    const newParameters = Immutable.fromJS({
      newParameter: newParameterState('newParameter'),
    });
    const { existingParameters, onClose } = this.props;

    return (
      <ParameterDeclarationForm allowEditingName
                                show
                                existingParameters={existingParameters}
                                parameters={newParameters}
                                data-testid="parameter-declaration-form"
                                onSave={this.onSave}
                                onClose={onClose} />
    );
  }
}

export default connect(CreateParameterDialog, { existingParameters: SearchParameterStore });
