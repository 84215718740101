import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import type { $PropertyType } from 'utility-types';

import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import { Button, ButtonToolbar } from 'components/bootstrap';
import { IfPermitted, Spinner } from 'components/common';

import TeamsDomain from '../../domainActions/TeamsDomain';
import type Team from '../../logic/Team';

type Props = {
  team: Team,
  readOnly: boolean,
};

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const _onDelete = (team: Team, setDeleting: (deleting: boolean) => void) => {
  let confirmMessage = `Do you really want to delete team "${team.name}"?`;

  if (team.users.size >= 1) {
    confirmMessage += `\n\nIt is still assigned to ${team.users.size} users.`;
  }

  // eslint-disable-next-line no-alert
  if (window.confirm(confirmMessage)) {
    setDeleting(true);

    TeamsDomain.delete(team).then(() => {
      setDeleting(false);
    });
  }
};

const EditActions = ({ team, readOnly }: { team: $PropertyType<Props, 'team'>, readOnly: boolean}) => {
  const [deleting, setDeleting] = useState(false);

  if (readOnly) {
    return null;
  }

  return (
    <ButtonToolbar>
      <IfPermitted permissions={[`teams:update:${team.id}`]}>
        <LinkContainer to={Routes.pluginRoute('SYSTEM_TEAMS_TEAMID_EDIT')(team.id)}>
          <Button id={`edit-user-${team.id}`} bsSize="xs" title={`Edit team ${team.name}`}>
            Edit
          </Button>
        </LinkContainer>
      </IfPermitted>
      <IfPermitted permissions={[`teams:delete:${team.id}`]}>
        <Button id={`delete-user-${team.id}`} onClick={() => _onDelete(team, setDeleting)} bsStyle="danger" bsSize="xs" title={`Delete team ${team.name}`}>
          {deleting ? <Spinner text="Deleting" delay={0} /> : 'Delete'}
        </Button>
      </IfPermitted>
    </ButtonToolbar>
  );
};

const ActionsCell = ({ team, readOnly }: Props) => {
  return (
    <td>
      <ActionsWrapper>
        <EditActions team={team} readOnly={readOnly} />
      </ActionsWrapper>
    </td>
  );
};

export default ActionsCell;
