import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import UserNotification from 'util/UserNotification';
import { AuthenticationActions } from 'stores/authentication/AuthenticationStore';
import { onOidcBackendUpdate } from 'authentication/components/oidc/bindings';

import type { ConfigFormValues, OidcSubmitPayload } from '../types';

export const handleCreate = (payload: OidcSubmitPayload, formValues: ConfigFormValues, _backendGroupSyncIsActive: boolean, shouldUpdateGroupSync: boolean | undefined = true) => {
  const notifyOnSuccess = () => UserNotification.success('Authentication service was updated successfully.', 'Success');
  const notifyOnError = (error) => UserNotification.error(`Updating authentication service failed with status: ${error}`, 'Error');

  return AuthenticationActions.create(payload).then((result) => {
    if (result.backend && formValues.synchronizeGroups && shouldUpdateGroupSync) {
      return onOidcBackendUpdate(false, formValues, result.backend.id, OKTA_TYPE_KEY).then(notifyOnSuccess);
    }

    notifyOnSuccess();

    return result;
  }).catch((error) => {
    notifyOnError(error);
    throw error;
  });
};

export const handleUpdate = (payload: OidcSubmitPayload, backendId: string, formValues: ConfigFormValues, backendGroupSyncIsActive: boolean, shouldUpdateGroupSync: boolean | undefined = true) => {
  const notifyOnSuccess = () => UserNotification.success('Authentication service was updated successfully.', 'Success');
  const notifyOnError = (error) => UserNotification.error(`Updating authentication service failed with status: ${error}`, 'Error');

  return AuthenticationActions.update(backendId, {
    ...payload,
    id: backendId,
  }).then((result) => {
    if (result.backend && shouldUpdateGroupSync) {
      return onOidcBackendUpdate(backendGroupSyncIsActive, formValues, result.backend.id, OKTA_TYPE_KEY).then(notifyOnSuccess);
    }

    notifyOnSuccess();

    return result;
  }).catch((error) => {
    notifyOnError(error);
    throw error;
  });
};
