import * as React from 'react';
import { useState, useCallback } from 'react';

import { Col, Row } from 'components/bootstrap';
import PaginatedList from 'components/common/PaginatedList';
import EntityList from 'components/common/EntityList';
import SearchForm from 'components/common/SearchForm';
import Spinner from 'components/common/Spinner';
import useUserSearchFiltersQuery from 'search-filter/hooks/useUserSearchFiltersQuery';
import QueryHelper from 'components/common/QueryHelper';
import { FILTER_SCOPES } from 'search-filter/constants';

import FiltersOverviewItem from './FiltersOverviewItem';

const PAGE_SIZES = [10, 50, 100];

const DEFAULT_QUERY = {
  query: '',
  per_page: 10,
  page: 1,
  sort: 'title',
  order: 'asc',
};

const FiltersOverview = () => {
  const [filterQuery, setSearchFilter] = useState(DEFAULT_QUERY);
  const { data: { filters = [], pagination }, isFetching } = useUserSearchFiltersQuery({ ...filterQuery, scope: FILTER_SCOPES.MY_FILTERS });
  const handlePaginationChange = useCallback((newPage: number, newPerPage: number) => setSearchFilter((cur) => ({ ...cur, page: newPage, per_page: newPerPage })), []);
  const handleSearch = useCallback((newSearchQuery: string | undefined) => setSearchFilter((cur) => ({ ...cur, page: DEFAULT_QUERY.page, query: newSearchQuery })), []);
  const handleReset = useCallback(() => handleSearch(undefined), [handleSearch]);

  return (
    <Row className="content">
      <Col xs={12}>
        <PaginatedList onChange={handlePaginationChange}
                       totalItems={pagination?.total ?? 0}
                       activePage={filterQuery.page}
                       pageSize={filterQuery.per_page}
                       pageSizes={PAGE_SIZES}
                       useQueryParameter={false}>
          <SearchForm onSearch={handleSearch}
                      onReset={handleReset}
                      topMargin={0}
                      queryHelpComponent={<QueryHelper entityName="filter" commonFields={['id', 'title', 'description']} fieldMap={{ queryString: 'Query string of the filter' }} />} />
          <EntityList items={filters.map((filter) => <FiltersOverviewItem key={filter.id} filter={filter} />)}
                      noItemsText={isFetching ? <Spinner delay={0} /> : 'No filters have been found.'} />
        </PaginatedList>
      </Col>
    </Row>
  );
};

export default FiltersOverview;
