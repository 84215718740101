import * as React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

import useCurrentUser from 'hooks/useCurrentUser';
import Routes from 'routing/Routes';
import history from 'util/History';
import { isPermitted } from 'util/PermissionsMixin';

type RequiredPermissionsFunction<P> = (props: P) => string[];
type ComponentType<P> = React.ComponentType<P>;

const withIsPermitted = <Props extends object>(
  Component: ComponentType<Props>,
  requiredPermissions: string[] | RequiredPermissionsFunction<Props>,
): React.ComponentType<Props> => (props: Props) => {
    const currentUser = useCurrentUser();

    const effectiveRequiredPermissions = isFunction(requiredPermissions) ? (requiredPermissions as RequiredPermissionsFunction<Props>)(props) : requiredPermissions;

    if (!isPermitted(currentUser?.permissions, effectiveRequiredPermissions)) {
      history.push(Routes.NOTFOUND);

      return null;
    }

    return <Component {...props} />;
  };

withIsPermitted.propTypes = {
  requiredPermissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  Component: PropTypes.element,
};

export default withIsPermitted;
