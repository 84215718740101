/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import { DocumentTitle } from 'components/common';
import WizardPageHeader from 'components/authentication/directoryServices/ldap/WizardPageHeader';
import SectionComponent from 'components/common/Section/SectionComponent';
import history from 'util/History';
import Routes from 'routing/Routes';
import OidcBackendMetaProvider from 'authentication/components/oidc/config/components/OidcBackendMetaProvider';
import LicenseCheck from 'license/LicenseCheck';

import BackendWizard from './BackendWizard';
import type { ConfigFormValues } from './types';
import { handleCreate } from './helpers/handleSubmit';
import payloadFromFormValues from './helpers/payloadFromFormValues';
import getDisplayName from './helpers/getDisplayName';

const BackendCreate = () => {
  const location = useLocation();
  const backendType = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const isOkta = backendType === OKTA_TYPE_KEY;
  const displayName = getDisplayName(backendType);

  const onSubmitForm = (values: ConfigFormValues, shouldUpdateGroupSync?: boolean) => {
    return handleCreate(payloadFromFormValues(values), values, shouldUpdateGroupSync)
      .then(() => {
        history.push(Routes.SYSTEM.AUTHENTICATION.BACKENDS.OVERVIEW);
      }).catch((error) => {
        throw error;
      });
  };

  const title = `Create ${displayName} Authentication Service`;

  return (
    <DocumentTitle title={title}>
      <WizardPageHeader title={title} />
      <SectionComponent title={title}>
        <LicenseCheck>
          {({ licenseIsValid }) => {
            return (
              <OidcBackendMetaProvider defaultValue={{ backendId: undefined, backendGroupSyncIsActive: false, licenseIsValid }}>
                <BackendWizard onSubmitForm={onSubmitForm} groupSyncValues={{}} backendType={backendType} isOkta={isOkta} />
              </OidcBackendMetaProvider>
            );
          }}
        </LicenseCheck>
      </SectionComponent>
    </DocumentTitle>
  );
};

export default BackendCreate;
