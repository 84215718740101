import * as React from 'react';
import { useQuery } from '@tanstack/react-query';

import getEventTypeCategories from 'search/contexts/api/eventTypeCategories';
import useLicenseCheck from 'license/useLicenseCheck';
import type { EventCategories } from 'security-content/types';
import UserNotification from 'util/UserNotification';

import MessageSummaryTemplateContext from './MessageSummaryTemplateContext';

type Props = {
  children: React.ReactNode;
}

export default function MessageSummaryTemplateProvider({ children }: Props) {
  const { security: { isValid: isValidSecurityLicense } } = useLicenseCheck();

  const {
    isLoading,
    isError,
    error,
    data: eventTypeCategories,
  } = useQuery<EventCategories, Error>(['eventTypeCategories'], getEventTypeCategories, {
    enabled: isValidSecurityLicense,
    onError: (fetchError: Error) => {
      UserNotification.error(`Something went wrong: ${fetchError.message}`);
    },
  });

  const contextValue = React.useMemo(() => {
    return {
      isLoading: isLoading,
      isError: isError,
      error: error,
      eventTypeCategories: eventTypeCategories,
    };
  }, [isLoading, isError, error, eventTypeCategories]);

  return (
    <MessageSummaryTemplateContext.Provider value={contextValue}>
      {children}
    </MessageSummaryTemplateContext.Provider>
  );
}
