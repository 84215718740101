import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';

import AppConfig from 'util/AppConfig';
import type { Colors, ThemeColorModes } from 'theme/colors';
import colors from 'theme/colors';
import {
  generateGlobalColors,
  generateGrayScale,
  generateInputColors,
  generateTableColors,
  generateVariantColors,
} from 'theme/variants/util';
import type { ThemeMode } from 'theme/constants';
import RegeneratableThemeContext from 'theme/RegeneratableThemeContext';
import notifyingAction from 'domainActions/notifyingAction';

import { CustomizationActions } from '../stores/CustomizationStore';

interface generateFn {
  graylogColors: Colors,
  mode: ThemeMode,
  initialLoad: boolean,
}

function colorGenerator({ currentThemeColors, mode }) {
  const tableColors = generateTableColors(mode, currentThemeColors.variant);
  const inputColors = generateInputColors(mode, currentThemeColors.global, currentThemeColors.gray, currentThemeColors.variant);
  const grayColors = generateGrayScale(currentThemeColors.global.textDefault, currentThemeColors.global.textAlt);

  const globalColors = {
    ...currentThemeColors.global,
    ...generateGlobalColors(mode, currentThemeColors.brand, currentThemeColors.global, currentThemeColors.variant),
  };

  const variantColors = {
    ...currentThemeColors.variant,
    ...generateVariantColors(mode, currentThemeColors.variant),
  };

  return {
    ...currentThemeColors,
    gray: grayColors,
    global: globalColors,
    input: inputColors,
    table: tableColors,
    variant: variantColors,
  };
}

const handleLoadTheme = () => CustomizationActions.loadTheme();

const handleSaveTheme = notifyingAction({
  action: CustomizationActions.updateTheme,
  success: () => ({
    message: 'Custom theme successfully saved!',
  }),
  error: (error) => ({
    message: `Error Saving: ${error}`,
  }),
});

const handleResetTheme = notifyingAction({
  action: CustomizationActions.updateTheme,
  success: () => ({
    message: 'Theme successfully reset to Graylog\'s default colors.',
  }),
  error: (error) => ({
    message: `Error Resetting: ${error}`,
  }),
});

const handleRevertTheme = notifyingAction({
  action: CustomizationActions.loadTheme,
  success: () => ({
    message: 'Theme successfully reverted to last saved colors.',
  }),
  error: (error) => ({
    message: `Error Reverting: ${error}`,
  }),
});

export const generateCustomThemeColors = ({ graylogColors, mode, initialLoad }: generateFn) => {
  const generateColors = (customColors) => {
    const currentThemeColors = merge({}, graylogColors, customColors[mode]);

    return colorGenerator({ currentThemeColors, mode });
  };

  if (initialLoad) {
    return Promise.resolve(AppConfig.customThemeColors()).then(generateColors);
  }

  return handleLoadTheme().then(generateColors);
};

const useThemeCustomizer = () => {
  const [currentColors, setCurrentColors] = useState<ThemeColorModes| undefined>();
  const [customThemeColors, setCustomThemeColors] = useState<Partial<ThemeColorModes> | undefined>();
  const isDefaultColors = useMemo(() => isEqual(colors, currentColors), [currentColors]);
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const { regenerateTheme } = useContext(RegeneratableThemeContext);

  const handleThemeRegenerate = (nextColors) => {
    regenerateTheme();

    return nextColors;
  };

  const onChangeTheme = useCallback(({ mode, key, type, hex }) => {
    const changedCustomColors = {
      ...customThemeColors,
      [mode]: {
        ...customThemeColors?.[mode],
        [type]: {
          ...customThemeColors?.[mode]?.[type],
          [key]: hex,
        },
      },
    };

    setCustomThemeColors(changedCustomColors);

    setIsSaved(false);
  }, [customThemeColors]);

  const onSaveTheme = () => {
    return handleSaveTheme(customThemeColors).then(handleThemeRegenerate).then(() => {
      setIsSaved(true);

      return customThemeColors;
    });
  };

  const onRevertTheme = () => {
    return handleRevertTheme().then(handleThemeRegenerate).then((revertedColors) => {
      setCustomThemeColors(revertedColors);
      setIsSaved(true);

      return revertedColors;
    });
  };

  const onResetTheme = () => {
    const emptyThemeColors = {};

    return handleResetTheme(emptyThemeColors).then(handleThemeRegenerate).then(() => {
      setCustomThemeColors(emptyThemeColors);
      setIsSaved(true);

      return emptyThemeColors;
    });
  };

  useEffect(() => {
    if (!customThemeColors) {
      handleLoadTheme().then((loadedColors) => {
        setCustomThemeColors(loadedColors);
      }).catch(() => {
        setCustomThemeColors(AppConfig.customThemeColors());
      });
    } else {
      setCurrentColors(merge({}, colors, customThemeColors));
    }
  }, [customThemeColors]);

  return {
    currentColors,
    customThemeColors,
    isDefaultColors,
    isSaved,
    onChangeTheme,
    onResetTheme,
    onRevertTheme,
    onSaveTheme,
  };
};

export default useThemeCustomizer;
