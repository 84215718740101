import styled, { css } from 'styled-components';

import { Button } from 'components/bootstrap';

const getVariant = (props: any) => {
  if (props.$variant === 'primary') {
    return `
      background-color: ${props.theme.colors.brand.primary} !important;
      color: white !important;
      border: 2px solid ${props.theme.colors.brand.primary} !important;
      outline: none !important;
    `;
  }

  return `
    background-color: rgba(0,0,0,0) !important;
    color: ${props.theme.colors.brand.primary} !important;
    border: 2px solid ${props.theme.colors.brand.primary} !important;
    outline: none !important;
  `;
};

const getSize = (props: any) => {
  if (props.$size === 'small') {
    return `
      padding: 3px 6px;
      font-size: 12px;
    `;
  }

  if (props.$size === 'big') {
    return `
      padding: 12px 24px;
    `;
  }

  return `
    padding: 6px 12px;
  `;
};

const HeaderButton = styled(Button)<{ $size?: 'small' | 'big', $variant?: 'primary' | 'secondary' }>`${
  (props: any) => {
    let style = getVariant(props);
    style += getSize(props);

    return css`${style}`;
  }
}`;

export default HeaderButton;
