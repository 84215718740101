import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'components/bootstrap';

import ForwarderSelectionList from './ForwarderSelectionList';

import { StyledSection, StyledTitle } from '../StyledWizardComponents';
import type { Forwarder } from '../../Types';
import { ForwarderPropType } from '../../Types';

type Props = {
  forwarders: Array<Forwarder>,
  onForwarderSelect: () => void,
  onStartForwarderClick: () => void,
};

const SelectForwarder = ({ forwarders, onForwarderSelect, onStartForwarderClick }: Props) => {
  const handleForwarderSelection = () => {
    onForwarderSelect();
  };

  return (
    <Row>
      <Col md={6}>
        <StyledTitle>Select Forwarder</StyledTitle>
        <StyledSection>
          <p>Haven&apos;t started a Forwarder yet? Click here for instructions.</p>
          <Button bsStyle="success" onClick={onStartForwarderClick}>Start new Forwarder</Button>
        </StyledSection>

        <StyledSection>
          <p>Or select one of the recently registered Forwarders from the list:</p>
          <ForwarderSelectionList forwarders={forwarders} onForwarderSelect={handleForwarderSelection} />
        </StyledSection>
      </Col>
    </Row>
  );
};

SelectForwarder.propTypes = {
  forwarders: PropTypes.arrayOf(ForwarderPropType),
  onForwarderSelect: PropTypes.func.isRequired,
  onStartForwarderClick: PropTypes.func.isRequired,
};

SelectForwarder.defaultProps = {
  forwarders: undefined,
};

export default SelectForwarder;
