import * as React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import { useFormikContext, Field } from 'formik';

import { Select } from 'components/common';
import StringUtils from 'util/StringUtils';

const OptionValue = ({ label }: { label: string }) => <span>Day {label}</span>;

type Props = {
  id: string,
  required?: boolean,
}

const DayOfMonthSelect = ({ id, required }: Props) => {
  const { setFieldTouched } = useFormikContext();
  const daysOptions = range(1, 31 + 1).map((day) => {
    const stringDay = String(day);

    return { value: stringDay, label: stringDay };
  });

  return (
    <Field name={id}>
      {({ field: { name, value, onChange } }) => (
        <Select onBlur={() => setFieldTouched(name, true)}
                onChange={(newValue: string) => onChange({
                  target: { value: newValue, name },
                })}
                options={daysOptions}
                inputId={name}
                placeholder="Select day of the month"
                inputProps={{ 'aria-label': 'Select day of the month' }}
                required={required}
                valueRenderer={OptionValue}
                value={value ? StringUtils.stringify(value) : undefined} />
      )}
    </Field>
  );
};

DayOfMonthSelect.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

DayOfMonthSelect.defaultProps = {
  required: false,
};

export default DayOfMonthSelect;
