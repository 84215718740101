import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Col, Row } from 'components/bootstrap';

import { StyledTitle } from './StyledWizardComponents';
import forwarderDiagram from './forwarder-diagram.svg';

const StyledContent = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;
`;

const StyledDiagram = styled.img`
  margin-top: 35px;
  max-width: 600px;
  width: 100%;
`;

type Props = {
  onStepComplete: () => void,
};

const WelcomeStep = ({ onStepComplete }: Props) => {
  return (
    <Row>
      <Col md={5} lg={6}>
        <StyledTitle>Send data to Graylog</StyledTitle>
        <StyledContent>
          Use Graylog Forwarder to get your data into Graylog.
          The Forwarder runs in your infrastructure and can run Inputs that receive logs from your
          applications and systems.
          Graylog allows you to set up a connection to one or more Forwarders that can send messages.
        </StyledContent>
        <Button bsStyle="success" onClick={onStepComplete}>Start configuration</Button>
      </Col>
      <Col md={6} mdOffset={1} lg={4} lgOffset={1}>
        <StyledDiagram src={forwarderDiagram} alt="Graylog Forwarder Diagram" />
      </Col>
    </Row>
  );
};

WelcomeStep.propTypes = {
  onStepComplete: PropTypes.func.isRequired,
};

WelcomeStep.defaultProps = {};

export default WelcomeStep;
