import * as React from 'react';
import { useCallback, useRef } from 'react';
import { union } from 'lodash';
import { useFormikContext } from 'formik';
import type * as Immutable from 'immutable';

import ParameterDeclarationForm from 'enterprise/parameters/components/ParameterDeclarationForm';
import { useStore } from 'stores/connect';
import { SearchMetadataStore } from 'views/stores/SearchMetadataStore';
import { Button } from 'components/bootstrap';
import type { QueryValidationState } from 'views/components/searchbar/queryvalidation/types';
import createParametersFromNames from 'enterprise/parameters/components/CreateParametersFromNames';
import type Parameter from 'views/logic/parameters/Parameter';
import useParameterDeclarationContext from 'search-filter/hooks/useParameterDeclarationContext';

import { SearchParameterStore, SearchParameterActions } from '../stores/SearchParameterStore';

type Props = {
  validationState?: QueryValidationState;
};
type ParameterDeclarationContextType = {
    onSaveParametersSuccess?: (
      params: {
        declarationFormRef?: React.RefObject<ParameterDeclarationForm>,
      }) => void
}

const QueryValidationParameterDeclaration = ({ validationState }: Props) => {
  const parameterDeclarationContext = useParameterDeclarationContext<ParameterDeclarationContextType>();
  const existingParameters = useStore(SearchParameterStore);
  const { undeclared: undeclaredParameterNamesFromSearch } = useStore(SearchMetadataStore);
  const declarationFormRef = useRef<ParameterDeclarationForm | undefined>();

  const parameterValidationErrors = validationState?.explanations.filter((explanation) => explanation.errorType === 'UNDECLARED_PARAMETER');
  const undeclaredParameterNamesFromValidation = parameterValidationErrors?.flatMap(({ relatedProperty }) => relatedProperty).filter((paramName) => !!paramName);
  const undeclaredParameterNames = union(undeclaredParameterNamesFromSearch.toArray(), undeclaredParameterNamesFromValidation);

  const { submitForm } = useFormikContext();
  const onSaveParameters = useCallback((parameters: Immutable.Map<string, Parameter>) => {
    return SearchParameterActions.declare(parameters).then(() => {
      const onSaveParametersSuccess = parameterDeclarationContext?.onSaveParametersSuccess;

      if (onSaveParametersSuccess) {
        onSaveParametersSuccess({ declarationFormRef });
      } else {
        submitForm();
      }
    });
  }, [parameterDeclarationContext, submitForm]);

  const openParameterDeclarationForm = () => {
    if (declarationFormRef.current) {
      declarationFormRef.current.open();
    }
  };

  if (!undeclaredParameterNames?.length) {
    return null;
  }

  const undeclaredParameters = createParametersFromNames(undeclaredParameterNames);

  return (
    <>
      <Button bsStyle="primary" bsSize="small" onClick={openParameterDeclarationForm}>Declare parameters</Button>
      <ParameterDeclarationForm ref={declarationFormRef}
                                existingParameters={existingParameters}
                                parameters={undeclaredParameters}
                                onSave={onSaveParameters} />
    </>
  );
};

QueryValidationParameterDeclaration.defaultProps = {
  validationState: undefined,
};

export default QueryValidationParameterDeclaration;
