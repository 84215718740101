import React, { useMemo, useContext } from 'react';

import { useStore } from 'stores/connect';
import { Button } from 'components/bootstrap';

import ForwardersContext from '../forwarders/ForwardersContext';
import ForwarderInputStatesStore, { ForwarderInputStatesActions } from '../stores/ForwarderInputStatesStore';
import type { Input, Forwarder, InputState } from '../Types';
import { ForwarderPropType, ForwarderInputPropType } from '../Types';

type Props = {
  input: Input,
  forwarder: Forwarder,
};

const ForwarderInputStateControl = ({ input, forwarder }: Props) => {
  const { forwarderInputStates } = useStore(ForwarderInputStatesStore);
  const { getForwarderContext } = useContext(ForwardersContext);
  const { state: forwarderState } = getForwarderContext(forwarder.id);

  const inputState = useMemo((): InputState | undefined => {
    const statesByInput = (forwarderInputStates || [])
      .filter((state) => state.input_id === input.id)
      .filter((state) => state.forwarder_id === forwarder.id)
      .map((state) => state.state);

    return statesByInput.length > 0 ? statesByInput[0] : undefined;
  }, [input, forwarder, forwarderInputStates]);

  if (forwarderState !== 'CONNECTED') {
    return (
      <Button bsStyle="default" disabled>
        Forwarder is not connected
      </Button>
    );
  }

  if (inputState === 'RUNNING') {
    return (
      <Button bsStyle="primary" onClick={() => ForwarderInputStatesActions.stopInput(forwarder, input)}>
        Stop input
      </Button>
    );
  }

  return (
    <Button bsStyle="success" onClick={() => ForwarderInputStatesActions.startInput(forwarder, input)}>
      Start input
    </Button>
  );
};

ForwarderInputStateControl.propTypes = {
  input: ForwarderInputPropType.isRequired,
  forwarder: ForwarderPropType.isRequired,
};

export default ForwarderInputStateControl;
