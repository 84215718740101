import { useEffect, useState } from 'react';

import type { OktaTeamSyncConfig } from 'logic/authentication/okta/types';
import { GroupSyncActions } from 'authentication/stores/directoryServices/GroupSyncStore';
import type GroupSyncBackend from 'authentication/logic/directoryServices/GroupSyncBackend';

const _prepareFormValues = (backendGroupSync) => {
  const {
    selectionType,
    selection,
    defaultRoles,
    config: {
      oktaApiToken,
    },
  } = backendGroupSync;

  return {
    teamDefaultRoles: defaultRoles.join(),
    teamSelectionType: selectionType,
    oktaApiToken,
    teamSelection: selection,
    synchronizeGroups: true,
  };
};

const useOktaInitialGroupSyncValues = (backendId: string, initialFormValues: OktaTeamSyncConfig) => {
  const [backendGroupSync, setBackendGroupSync] = useState<GroupSyncBackend | undefined>();
  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    if (backendId) {
      GroupSyncActions.load(backendId).then((result) => {
        if (result && result.backend) {
          setBackendGroupSync(result.backend);
        }

        setFinishedLoading(true);
      }).catch(() => {
        setFinishedLoading(true);
        setBackendGroupSync(undefined);
      });
    }
  }, [backendId]);

  return {
    finishedLoading: backendId ? finishedLoading : true,
    formValues: backendGroupSync ? _prepareFormValues(backendGroupSync) : initialFormValues,
  };
};

export default useOktaInitialGroupSyncValues;
