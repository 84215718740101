import { useQuery } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import type { SearchFilterUsagesSummary } from 'search-filter/types';

const USER_FILTER_SUMMARY_QUERY_KEY = 'user_filter_summary_query_key';

const fetchSearchFilterUsagesSummary = async (filterId: string): Promise<SearchFilterUsagesSummary> => {
  const url = `/plugins/org.graylog.plugins.searchfilters/search_filters_usage/summary/${filterId}`;

  return fetch('GET', qualifyUrl(url));
};

type Result = {
  data: SearchFilterUsagesSummary,
  isFetching: boolean,
  refetch?: () => Promise<{ data: SearchFilterUsagesSummary, isFetching: boolean }>
}

const useSearchFilterUsagesSummaryQuery = (filterId: string, options?: { enabled: boolean }): Result => {
  return useQuery([USER_FILTER_SUMMARY_QUERY_KEY, filterId, 'summary'], () => fetchSearchFilterUsagesSummary(filterId), {
    onError: (errorThrown) => {
      UserNotification.error(`Loading search filter usages summary for filter with id ${filterId} failed with status: ${errorThrown}`,
        'Could not load search filter usages summary');
    },
    keepPreviousData: true,
    initialData: {
      summary: [],
    },
    ...options,
  });
};

export default useSearchFilterUsagesSummaryQuery;
