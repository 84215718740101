import type { AvailableWidgetPreview, BackendReportWidget, SchedulingFrequency, WeeklyFrequency, MonthlyFrequency } from 'report/types';

export const isReportBackendWidget = (widget: BackendReportWidget | AvailableWidgetPreview): widget is BackendReportWidget => {
  return 'dashboard_widget_id' in widget && !('id' in widget);
};

export const isAvailableWidgetPreview = (widget: BackendReportWidget | AvailableWidgetPreview): widget is AvailableWidgetPreview => {
  return 'id' in widget && !('dashboard_widget_id' in widget);
};

export const isWeeklyFrequency = (schedulingFrequency: SchedulingFrequency | Partial<SchedulingFrequency>): schedulingFrequency is WeeklyFrequency | Partial<WeeklyFrequency> => {
  return schedulingFrequency.frequency === 'WEEKLY';
};

export const isMonthlyFrequency = (schedulingFrequency: SchedulingFrequency | Partial<SchedulingFrequency>): schedulingFrequency is MonthlyFrequency | Partial<MonthlyFrequency> => {
  return schedulingFrequency.frequency === 'MONTHLY';
};
