import * as Immutable from 'immutable';

import Parameters from 'enterprise/parameters/components/Parameters';
import { SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';
import OptionParameter from 'enterprise/parameters/components/option/OptionParameter';
import useParameters from 'views/hooks/useParameters';
import { SearchExecutionStateActions } from 'views/stores/SearchExecutionStateStore';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type Parameter from 'views/logic/parameters/Parameter';
import type { SearchBarControl } from 'views/types';

const parameterIsRequired = (parameter: Parameter) => {
  return (parameter.needsBinding && !parameter.optional) && parameter.type !== OptionParameter.type;
};

const onParameterSubmit = async ({ parameterBindings }: { parameterBindings?: { [parameterName: string]: string | number | boolean } }) => {
  if (parameterBindings) {
    const newParameterBindings: Immutable.Map<string, any> = Immutable.Map(Object.entries(parameterBindings ?? {}));

    await SearchExecutionStateActions.setParameterValues(newParameterBindings);
  }

  return Promise.resolve(undefined);
};

const useInitialParameterValues = () => {
  const { parameterBindings } = useParameters();

  return ({
    parameterBindings: parameterBindings.mapEntries(([fieldName, { value }]) => ([fieldName, value])).toJS(),
  });
};

const parameterSearchBarControls: SearchBarControl = {
  id: 'parameter-input-bar',
  component: Parameters,
  useInitialSearchValues: useInitialParameterValues,
  useInitialDashboardWidgetValues: useInitialParameterValues,
  onSearchSubmit: onParameterSubmit,
  onDashboardWidgetSubmit: onParameterSubmit,
  validationPayload: (values: { parameterBindings }) => {
    const parameters = SearchParameterStore.getInitialState();
    const parameterBindings = Object.fromEntries(Object.entries(values.parameterBindings ?? {}).map(([fieldName, value]) => [fieldName, ParameterBinding.forValue(value)]));

    return ({ parameters: parameters.toArray(), parameter_bindings: parameterBindings });
  },
  onValidate: ({ parameterBindings }) => {
    const parameters = SearchParameterStore.getInitialState();
    const parametersWhichNeedABinding = parameters?.filter(parameterIsRequired) ?? Immutable.List();
    const parameterErrors = parametersWhichNeedABinding.map(({ name }) => [name, !parameterBindings?.[name] && 'Parameter value is required']).filter(([_name, error]) => !!error);

    if (parameterErrors.size) {
      return { parameterBindings: Object.fromEntries(parameterErrors.toArray()) };
    }

    return {};
  },
  placement: 'right',
} as const;

export default parameterSearchBarControls;
