import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { useStore } from 'stores/connect';
import type ParameterType from 'views/logic/parameters/Parameter';
import { ViewStore } from 'views/stores/ViewStore';
import { SearchParameterActions, SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';
import { Icon } from 'components/common';
import { Button } from 'components/bootstrap';
import CreateParameterDialog from 'enterprise/parameters/creatoractions/CreateParameterDialog';

import Parameter from './Parameter';

const handleDelete = (parameterName: string, formFieldName: string, setFieldValue: (fieldName: string, value: undefined) => void) => {
  return SearchParameterActions.remove(parameterName).then(() => {
    setFieldValue(formFieldName, undefined);
  });
};

const handleEdit = (name: string, parameter: ParameterType) => SearchParameterActions.update(name, parameter);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const CreateButton = styled(Button)(({ $displaySpacer }: { $displaySpacer: boolean }) => `
  margin-left: 5px;
  margin-right: ${$displaySpacer ? '5px;' : '0'};
`);

const Parameters = () => {
  const { setFieldValue } = useFormikContext();
  const parameters = useStore(SearchParameterStore);
  const searchId = useStore(ViewStore, ({ view }) => view?.search?.id);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const toggleIsCreateFormOpen = useCallback(() => setIsCreateFormOpen((isOpen) => !isOpen), []);

  const parameterFields = useMemo(() => {
    const onDelete = (parameterName: string, formFieldName) => handleDelete(parameterName, formFieldName, setFieldValue);

    return parameters
      .sort((p1: ParameterType, p2: ParameterType) => p1.title.localeCompare(p2.title))
      .map((param) => (
        <div key={param.name}>
          <Parameter parameter={param}
                     searchId={searchId}
                     onDelete={onDelete}
                     onEdit={handleEdit} />
        </div>

      ))
      .valueSeq()
      .toJS();
  }, [parameters, searchId, setFieldValue]);

  return (
    <Wrapper>
      <div>
        Parameters
        <CreateButton bsSize="small" onClick={toggleIsCreateFormOpen} title="Declare parameters" $displaySpacer={!!parameterFields?.length}>
          <Icon name="plus" />
        </CreateButton>
        {isCreateFormOpen && <CreateParameterDialog onClose={toggleIsCreateFormOpen} />}
      </div>
      {parameterFields}
    </Wrapper>
  );
};

export default Parameters;
