import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';
import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import { Field } from 'formik';

import copyToClipboard from 'util/copyToClipboard';
import { useStore } from 'stores/connect';
import { DropdownButton, InputGroup, FormGroup, MenuItem } from 'components/bootstrap';
import CustomPropTypes from 'views/components/CustomPropTypes';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import ParameterType from 'views/logic/parameters/Parameter';
import TypeSpecificParameterInput from 'enterprise/parameters/components/TypeSpecificParameterInput';
import { SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';

import ParameterDeclarationForm from './ParameterDeclarationForm';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0;
`;

const StyledDropdownButton = styled(DropdownButton)(({ theme, $hasError }: { theme: DefaultTheme, $hasError: boolean }) => `
  ${$hasError ? css`
    && {
      border: 1px solid ${theme.colors.variant.light.danger};
      border-right: 0;
    }
  ` : ''}
`);

type Props = {
  parameter: ParameterType,
  searchId: string,
  onDelete: (parameterName: string, formFieldName: string) => void,
  onEdit: (parameterName: string, parameter: ParameterType) => Promise<ParameterMap>,
  inputSize: 'small' | 'large';
};

const Parameter = ({ parameter, searchId, onDelete, onEdit, inputSize }: Props) => {
  const { name: parameterName, title } = parameter;
  const parameters = Immutable.fromJS({ [parameterName]: parameter });
  const declarationForm = useRef<ParameterDeclarationForm>();
  const fieldName = `parameterBindings.${parameterName}`;

  const existingParameters = useStore(SearchParameterStore) ?? Immutable.Map();
  const existingParametersWithoutCurrentOne = existingParameters.remove(parameterName);

  const handleEdit = useCallback(() => {
    declarationForm.current.open();
  }, []);

  const handleDelete = useCallback(() => {
    onDelete(parameterName, fieldName);
  }, [onDelete, fieldName, parameterName]);

  const handleUpdate = useCallback((newParameters: ParameterMap) => {
    const newParameter = newParameters.get(parameterName);

    declarationForm.current.close();

    return onEdit(parameterName, newParameter);
  }, [onEdit, parameterName]);

  const onValueChange = useCallback((name, onChange) => (_, newValue) => onChange({
    target: { name, value: newValue },
  }), []);

  const copyParameterNameToClipboard = useCallback(() => copyToClipboard(`$${parameterName}$`), [parameterName]);

  return (
    <Field name={fieldName}>
      {({ field: { onChange, onBlur, value, name }, meta: { error } }) => (
        <StyledFormGroup controlId={`form-inline-${name}`} validationState={error ? 'error' : null} bsSize={inputSize}>
          <InputGroup bsSize={inputSize}>
            <StyledDropdownButton componentClass={InputGroup.Button}
                                  id={`parameter-dropdown-${name}`}
                                  bsSize="small"
                                  $hasError={!!error}
                                  title={title}>
              <MenuItem key="edit" onSelect={handleEdit}>Edit</MenuItem>
              <MenuItem key="delete" onSelect={handleDelete}>Delete</MenuItem>
              <MenuItem divider />
              <MenuItem key="copy" onSelect={copyParameterNameToClipboard}>Copy to clipboard</MenuItem>
            </StyledDropdownButton>

            <TypeSpecificParameterInput parameter={parameter}
                                        value={value ?? ''}
                                        inputSize={inputSize}
                                        onChange={onValueChange(name, onChange)}
                                        onBlur={onBlur}
                                        searchId={searchId} />

            <ParameterDeclarationForm ref={declarationForm}
                                      existingParameters={existingParametersWithoutCurrentOne}
                                      parameters={parameters}
                                      onSave={handleUpdate} />
          </InputGroup>
        </StyledFormGroup>
      )}
    </Field>
  );
};

Parameter.propTypes = {
  parameter: CustomPropTypes.instanceOf(ParameterType).isRequired,
  inputSize: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  searchId: PropTypes.string.isRequired,
};

Parameter.defaultProps = {
  inputSize: 'small',
};

export default Parameter;
