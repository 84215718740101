import * as React from 'react';
import { useMemo } from 'react';
import * as Immutable from 'immutable';

import type { BackendMessage } from 'views/components/messagelist/Types';
import ExportTimerangeContext from 'logview/components/contexts/ExportTimerangeContext';

type Props = {
  children: React.ReactNode,
  pages: { [pageIndex: string]: Array<BackendMessage> },
}

const firstMessageTimestamp = (pages: Immutable.OrderedSet<[number, Array<BackendMessage>]>) => {
  const [, oldestMessageChunk] = pages.first();
  const oldestMessage = oldestMessageChunk[oldestMessageChunk.length - 1];

  return oldestMessage?.message?.timestamp as string;
};

const lastMessageTimestamp = (pages: Immutable.OrderedSet<[number, Array<BackendMessage>]>) => {
  const [, newestMessageChunk] = pages.last();
  const newestMessage = newestMessageChunk[0];

  return newestMessage?.message?.timestamp as string;
};

const ExportTimerangeContextProvider = ({ children, pages }: Props) => {
  const exportTimerange = useMemo(() => {
    const orderedPages = Immutable.Map(pages).map<[number, BackendMessage[]]>((value, key) => [Number(key), value]).toOrderedSet();
    const first = firstMessageTimestamp(orderedPages);
    const last = lastMessageTimestamp(orderedPages);

    return { first, last };
  }, [pages]);

  return (
    <ExportTimerangeContext.Provider value={exportTimerange}>
      {children}
    </ExportTimerangeContext.Provider>
  );
};

export default ExportTimerangeContextProvider;
