import { useQuery } from '@tanstack/react-query';

import type { ArchiveConfiguration } from 'archive/types';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';

export const urlPrefix = '/api/plugins/org.graylog.plugins.archive';
export const urlSuffix = '/config';
export const ARCHIVE_CONFIG_QUERY_KEY = 'archiveConfig';

const refetchInterval = 5000;

const fetchArchiveConfig = async (): Promise<ArchiveConfiguration> => {
  return fetch('GET', `${urlPrefix}${urlSuffix}`);
};

const useArchiveConfig = () => {
  return useQuery([ARCHIVE_CONFIG_QUERY_KEY], () => fetchArchiveConfig(), {
    onError: (errorThrown) => {
      UserNotification.error(`Loading archive config failed with status: ${errorThrown}`);
    },
    retry: 0,
    refetchInterval: refetchInterval,
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'error'],
    initialData: {
      archive_path: '',
      max_segment_size: 0,
      segment_filename_prefix: '',
      segment_compression_type: 'NONE',
      metadata_filename: '',
      histogram_bucket_size: 0,
      restore_index_batch_size: 0,
      excluded_streams: [],
      segment_checksum_type: 'NONE',
      backend_id: '',
      archive_failure_threshold: 0,
      retention_time: 0,
      restrict_to_leader: true,
    },
  });
};

export default useArchiveConfig;
