import type { IconName } from 'components/common/Icon';

type RouteObject = {
  id: string;
  path: string;
  pathName: string;
  iconName: IconName;
  title: string;
  exact: boolean;
}

export const securityRoutes: RouteObject[] = [
  {
    id: 'security-main',
    path: '/security',
    pathName: 'SECURITY',
    iconName: 'poll',
    title: 'Overview',
    exact: true,
  },
  {
    id: 'security-user-activity',
    path: '/security/user-activity',
    pathName: 'SECURITY_USER-ACTIVITY',
    iconName: 'user',
    title: 'User Activity',
    exact: false,
  },
  {
    id: 'security-host-activity',
    path: '/security/host-activity',
    pathName: 'SECURITY_HOST-ACTIVITY',
    iconName: 'tv',
    title: 'Host Activity',
    exact: false,
  },
  {
    id: 'security-network-activity',
    path: '/security/network-activity',
    pathName: 'SECURITY_NETWORK-ACTIVITY',
    iconName: 'wifi',
    title: 'Network Activity',
    exact: false,
  },
  {
    id: 'security-anomalies',
    path: '/security/anomalies',
    pathName: 'SECURITY_ANOMALIES',
    iconName: 'search-plus',
    title: 'Anomalies',
    exact: false,
  },
  {
    id: 'security-sigma',
    path: '/security/sigma',
    pathName: 'SECURITY_SIGMA',
    iconName: 'ruler',
    title: 'Sigma Rules',
    exact: false,
  },
];

export const anomalyDetectionRoutes: RouteObject[] = [
  {
    id: 'security-configuration',
    path: '/security/configuration',
    pathName: 'SECURITY_CONFIGURATION',
    iconName: 'wrench',
    title: 'Configuration',
    exact: false,
  },
];
