import LogViewComponent from 'logview/components/LogViewWidget';
import CreateLogViewFromMessageTable from 'logview/CreateLogViewFromMessageTable';
import Widget from 'views/logic/widgets/Widget';
import validLicensePresent from 'license/ValidLicensePresent';

import LogViewEditComponent from './components/LogViewEditWidget';
import AddLogViewActionHandler from './AddLogViewActionHandler';
import LogViewWidget from './logic/LogViewWidget';
import LogViewHandler from './LogViewHandler';
import LogViewConfigGenerator from './LogViewConfigGenerator';
import LogViewExportSettings from './components/LogViewExportSettings';

Widget.registerSubtype(LogViewWidget.type, LogViewWidget);

export default {
  enterpriseWidgets: [
    {
      type: 'LOGS',
      displayName: 'Log View',
      defaultHeight: 4,
      defaultWidth: 12,
      hasEditSubmitButton: true,
      visualizationComponent: LogViewComponent,
      editComponent: LogViewEditComponent,
      needsControlledHeight: () => false,
      searchResultTransformer: (data: Array<unknown>) => data[0],
      searchTypes: LogViewConfigGenerator,
      titleGenerator: () => 'Log View',
      exportComponent: LogViewExportSettings,
    },
  ],
  creators: [
    {
      type: 'preset',
      title: 'Log View',
      func: AddLogViewActionHandler,
      condition: validLicensePresent,
    },
  ],
  searchTypes: [
    {
      type: LogViewWidget.type,
      handler: LogViewHandler,
      default: {},
    },
  ],
  'views.widgets.actions': [CreateLogViewFromMessageTable],
};
