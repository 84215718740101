import type Immutable from 'immutable';

import { INLINE_SEARCH_FILTER_TYPE } from 'search-filter/constants';
import isFilterInaccessible from 'search-filter/logic/isFilterInaccessible';
import type { SearchFilter } from 'views/types';

const transformToInlineFilter = (filter: SearchFilter) => {
  const updatedFilter = { ...filter };
  delete updatedFilter.id;
  updatedFilter.type = INLINE_SEARCH_FILTER_TYPE;

  return updatedFilter;
};

const transformInaccessibleFilters = (filters: Immutable.List<SearchFilter>, userPermissions: Immutable.List<string>) => {
  return filters.map((filter) => {
    if (isFilterInaccessible(filter, userPermissions)) {
      return transformToInlineFilter(filter);
    }

    return filter;
  });
};

export default transformInaccessibleFilters;
