const securityContentThemeVariables = {
  measures: {
    SIDE_NAV_WIDTH: 300,
    CLOSED_NAV_LEFT: 400,
  },
  colors: {
    primaryDull: 'rgba(255,54,51, 0.2)',
  },
};

export default securityContentThemeVariables;
