import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';

import generateId from 'logic/generateId';
import { Spinner } from 'components/common';
import connect from 'stores/connect';
import Store from 'logic/local-storage/Store';
import {
  LOGIN_INITIALIZED_STATE,
  LOGIN_INITIALIZING_STATE,
  LOGIN_TRANSITIONING_STATE,
} from 'logic/authentication/constants';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';

import OidcCallback from './OidcCallback';
import { OidcConfigurationActions, OidcConfigurationStore } from './OidcConfigurationStore';
import { OidcSessionStore } from './OidcSessionStore';
import OidcGenericLoginLink from './OidcGenericLoginLink';

import OktaLoginForm from '../okta/OktaLoginForm';

const OidcLogin = ({ configuration, isLoading, onErrorChange, setLoginFormState }) => {
  const uri = useMemo(() => new URI(window.location.href), []);
  const configNotLoaded = isLoading || !configuration;

  useEffect(() => {
    OidcConfigurationActions.getConfig();
  }, []);

  const isCallbackUrl = useCallback(() => {
    return uri.path() === '/authorization-code/callback';
  }, [uri]);

  const generateNonce = (redirectTo = uri.resource()) => {
    return {
      state: generateId(),
      redirectTo: redirectTo,
    };
  };

  const [nonce, setNonce] = useState(() => {
    try {
      if (isCallbackUrl()) {
        return Store.sessionGet('nonce');
      }
    } catch (e) {
      return generateNonce();
    }

    return generateNonce();
  });

  const regenerateNonce = () => {
    setNonce((prevNonce) => {
      return generateNonce(prevNonce.redirectTo);
    });
  };

  useEffect(() => {
    Store.sessionSet('nonce', nonce);
  }, [nonce]);

  useEffect(() => {
    if (configNotLoaded) {
      setLoginFormState(LOGIN_INITIALIZING_STATE);
    } else if (isCallbackUrl()) {
      setLoginFormState(LOGIN_TRANSITIONING_STATE);
    } else {
      setLoginFormState(LOGIN_INITIALIZED_STATE);
    }
  }, [configNotLoaded, isCallbackUrl, setLoginFormState]);

  if (configNotLoaded) {
    return (
      <p className="loading-text">
        <Spinner text="Loading, please wait..." delay={0} />
      </p>
    );
  }

  if (isCallbackUrl()) {
    return (
      <OidcCallback configuration={configuration}
                    nonce={nonce}
                    onErrorChange={onErrorChange}
                    params={uri.query(true)}
                    regenerateNonce={regenerateNonce} />
    );
  }

  return configuration.oauth_type === OKTA_TYPE_KEY ? (
    <OktaLoginForm checkSession
                   configuration={configuration}
                   nonce={nonce}
                   onErrorChange={onErrorChange} />
  )
    : <OidcGenericLoginLink configuration={configuration} nonce={nonce} />;
};

OidcLogin.propTypes = {
  configuration: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onErrorChange: PropTypes.func.isRequired,
  setLoginFormState: PropTypes.func.isRequired,
};

OidcLogin.defaultProps = {
  configuration: undefined,
};

export default connect(OidcLogin, {
  configuration: OidcConfigurationStore,
  oidcSession: OidcSessionStore,
}, ({ configuration, oidcSession, ...props }) => ({
  ...props,
  configuration: configuration.configuration,
  isLoading: oidcSession.isLoading,
}));
