import * as React from 'react';
import moment from 'moment';
import { upperFirst } from 'lodash';

import type { SchedulingFrequency } from 'report/types';
import { fromFormStateValue as hourFromFormValue } from 'report/common/MinuteOfHourInput';
import { FREQUENCY_OPTIONS } from 'report/report-configuration-page/SchedulingFrequencyForm';
import toUTCFrequencyTime from 'report/report-configuration-page/toUTCFrequencyTime';

const PLACEHOLDER = <>No options selected.</>;

const TimeInfo = ({ time, timezone } : { time: string, timezone: string}) => {
  if (!time) {
    return <i>Time not configured</i>;
  }

  const utcHourAndMinute = toUTCFrequencyTime(time, timezone);

  return <>{time} {timezone ?? 'UTC'} timezone ({utcHourAndMinute} UTC)</>;
};

type Props = {
  frequency: SchedulingFrequency['frequency'],
  frequencyConfiguration: Partial<SchedulingFrequency['frequency_configuration']>,
}

const FrequencySummary = ({ frequency, frequencyConfiguration } : Props) => {
  if (!frequency || !frequencyConfiguration) {
    return PLACEHOLDER;
  }

  switch (frequency) {
    case FREQUENCY_OPTIONS.HOURLY.value:
      if (frequencyConfiguration.time) {
        return (
          <>
            Hourly on minute {hourFromFormValue(frequencyConfiguration.time)} of every hour
          </>
        );
      }

      break;
    case FREQUENCY_OPTIONS.DAILY.value:
      if (frequencyConfiguration.time) {
        return (
          <>
            Daily at <TimeInfo time={frequencyConfiguration.time} timezone={frequencyConfiguration.timezone} />.
          </>
        );
      }

      break;
    case FREQUENCY_OPTIONS.WEEKLY.value:
      if ('day_of_week' in frequencyConfiguration && frequencyConfiguration.day_of_week && frequencyConfiguration.time) {
        return (
          <>
            Weekly every {upperFirst(frequencyConfiguration.day_of_week)} at{' '}
            <TimeInfo time={frequencyConfiguration.time} timezone={frequencyConfiguration.timezone} />.
          </>
        );
      }

      break;
    case FREQUENCY_OPTIONS.MONTHLY.value:
      if ('day_of_month' in frequencyConfiguration && frequencyConfiguration.day_of_month && frequencyConfiguration.time) {
        const ordinalDay = moment.localeData().ordinal(upperFirst(frequencyConfiguration.day_of_month));

        return (
          <>
            Monthly the {ordinalDay} day at{' '}
            <TimeInfo time={frequencyConfiguration.time} timezone={frequencyConfiguration.timezone} />.
          </>
        );
      }

      break;
    default:
      break;
  }

  return PLACEHOLDER;
};

export default FrequencySummary;
