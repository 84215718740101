import * as React from 'react';
import type * as Immutable from 'immutable';
import styled, { css } from 'styled-components';

import { CELL_SEP } from 'logview/components/Constants';

import LogViewCell from './LogViewCell';

export const HEADER_HEIGHT = 24;

const TableHead = styled.div(({ theme }) => css`
  display: flex;
  min-width: fit-content;
  padding: 3px 5px;
  border-bottom: 1px solid ${theme.colors.gray[80]};

  height: ${HEADER_HEIGHT}px;
  background-color: ${theme.colors.gray[90]} !important; /* Needed for report generation */

  color-adjust: exact !important; /* Needed for report generation */
  align-items: center;
  position: sticky;
  top: 0;

  z-index: 1;

  :last-child {
    border-bottom: 0;
  }

  :hover {
    background-color: ${theme.colors.gray[90]};
    color: initial;
  }
`);

type Props = {
  columns: Immutable.Set<string>
}

const LogViewHeader = ({ columns }: Props) => {
  return (
    <TableHead>
      {columns.map((fieldName) => (
        <React.Fragment key={`table-header-cell-${fieldName}`}>
          <LogViewCell fieldName={fieldName}>
            {fieldName}
          </LogViewCell>
          {fieldName !== columns.last() ? CELL_SEP : null}
        </React.Fragment>
      ))}
    </TableHead>
  );
};

export default LogViewHeader;
