import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';

import { Col, Row, Input } from 'components/bootstrap';
import { FormSubmit, MultiSelect, Spinner } from 'components/common';
import FormikInput from 'components/common/FormikInput';
import history from 'util/History';
import Routes from 'routing/Routes';
import { UsersActions } from 'stores/users/UsersStore';
import type { ReportDelivery as TReportDelivery, Report } from 'report/types';

import ReportScheduling from './ReportScheduling';

import ReportsActions from '../ReportsActions';

type Props = {
  report: Report,
};

type UserOption = { value: string, label: string };
export type ReportDeliveryFormValues = Partial<TReportDelivery>;

const initialDeliveryState = (delivery: Report['delivery']): ReportDeliveryFormValues => ({
  active: delivery?.active,
  email_body: delivery?.email_body || '',
  email_subject: delivery?.email_subject || '',
  email_receivers: delivery?.email_receivers,
  scheduling: delivery?.scheduling,
  user_receivers: delivery?.user_receivers,
  next_execution: delivery?.next_execution,
});

const useLoadUsersOptions = (setUsers: (users: Array<UserOption>) => void) => {
  useEffect(() => {
    UsersActions.loadUsers().then((newUsers) => {
      const userOptions = newUsers
        .map<UserOption>(({ username, fullName }) => ({
          value: username,
          label: `${username} (${fullName})`,
        }))
        .toArray();
      setUsers(userOptions);
    });
  }, [setUsers]);
};

const validateForm = (values: ReportDeliveryFormValues) => {
  let errors = {};

  if (values.active) {
    if (!values.scheduling?.length) {
      errors = { ...errors, scheduling: 'Scheduling is required.' };
    }

    if (!values.email_subject) {
      errors = { ...errors, email_subject: 'Email subject is required.' };
    }

    if (!values.email_body) {
      errors = { ...errors, email_body: 'Email body is required.' };
    }

    if (!values.user_receivers?.length && !values.email_receivers?.length) {
      errors = {
        ...errors,
        email_receivers: 'Either email or user receivers are required.',
        user_receivers: 'Either email or user receivers are required.',
      };
    }
  }

  return errors;
};

const toSelectValue = (values: Array<string>) => (Array.isArray(values) ? values.join(',') : '');

const onCancel = (dataTouched: boolean) => {
  // eslint-disable-next-line no-alert
  if (!dataTouched || window.confirm('Do you really want to abandon this page and lose your changes? This action cannot be undone.')) {
    history.push(Routes.pluginRoute('REPORTS'));
  }
};

const saveDelivery = (reportId: string, newDelivery: TReportDelivery) => {
  return ReportsActions.updateDelivery(reportId, newDelivery).then(() => {
    history.push(Routes.pluginRoute('REPORTS'));
  });
};

const handleMultiSelectChange = (value: string) => (value === '' ? [] : value.split(','));

const ReportDelivery = ({ report }: Props) => {
  const [usersOptions, setUsersOptions] = useState<Array<UserOption>>();
  useLoadUsersOptions(setUsersOptions);

  if (!usersOptions) {
    return <Spinner />;
  }

  return (
    <Row>
      <Col md={12}>
        <h3>Delivery</h3>
        <p>Choose the recipients for this report and when the report should be scheduled for delivery.</p>
        <Formik initialValues={initialDeliveryState(report.delivery)}
                onSubmit={(values: TReportDelivery) => saveDelivery(report.id, values)}
                validateOnBlur={false}
                validate={validateForm}>
          {({ dirty, isSubmitting, isValidating, isValid, setFieldTouched, values }) => {
            const disableForm = !values.active;

            return (
              <Form>
                <FormikInput id="active"
                             name="active"
                             type="checkbox"
                             label="Send this report automatically on a regular basis" />
                <ReportScheduling disabled={!values.active} />
                <FormikInput id="email_subject"
                             name="email_subject"
                             label="Email subject"
                             help="Set an email subject to use when sending the report."
                             disabled={disableForm}
                             required />
                <FormikInput id="email_body"
                             name="email_body"
                             type="textarea"
                             label="Email body"
                             help="Add an email body to use when sending the report."
                             disabled={disableForm}
                             rows={6}
                             required />
                <Field name="user_receivers">
                  {({ field: { name, value, onChange }, meta: { error, touched } }) => (
                    <Input bsStyle={(error && touched) ? 'error' : undefined}
                           help="Select Graylog usernames that will receive this report."
                           error={error && touched ? error : undefined}
                           id={name}
                           label="User recipients">
                      <MultiSelect onBlur={() => setFieldTouched(name, true)}
                                   onChange={(selectedReceivers: string) => onChange({
                                     target: {
                                       value: handleMultiSelectChange(selectedReceivers),
                                       name,
                                     },
                                   })}
                                   options={usersOptions}
                                   disabled={disableForm}
                                   inputId={name}
                                   addLabelText="Type username"
                                   placeholder="Type username"
                                   value={toSelectValue(value)} />
                    </Input>
                  )}
                </Field>

                <Field name="email_receivers">
                  {({ field: { name, value, onChange }, meta: { error, touched } }) => (
                    <Input bsStyle={(error && touched) ? 'error' : undefined}
                           help="Add email addresses that will receive this report."
                           error={(error && touched) ? error : undefined}
                           id={name}
                           label="Email recipients">
                      <MultiSelect onBlur={() => setFieldTouched(name, true)}
                                   onChange={(selectedReceivers: string) => onChange({
                                     target: {
                                       value: handleMultiSelectChange(selectedReceivers),
                                       name,
                                     },
                                   })}
                                   options={[]}
                                   inputId={name}
                                   disabled={disableForm}
                                   addLabelText='Add email "{label}"?'
                                   placeholder="Type email address"
                                   allowCreate
                                   value={toSelectValue(value)} />
                    </Input>
                  )}
                </Field>
                <FormSubmit disabledSubmit={isValidating || !isValid}
                            isSubmitting={isSubmitting}
                            isAsyncSubmit
                            submitButtonText="Update delivery"
                            submitLoadingText="Updating delivery..."
                            onCancel={() => onCancel(dirty)} />
              </Form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};

ReportDelivery.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ReportDelivery;
