import * as React from 'react';
import { set } from 'lodash';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';

import { FormControl, FormGroup, Input, BootstrapModalWrapper, Modal } from 'components/bootstrap';
import ModalSubmit from 'components/common/ModalSubmit';
import FormikInput from 'components/common/FormikInput';
import { Icon, Select, HoverForHelp } from 'components/common';
import type { SchedulingFrequency } from 'report/types';
import FrequencySummary from 'report/report-configuration-page/FrequencySummary';
import { isWeeklyFrequency, isMonthlyFrequency } from 'report/typeGuards/reportingWidget';

import FrequencyControls from './FrequencyControls';

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export type SchedulingFrequencyFormValues = Partial<SchedulingFrequency>

// Sorted from smallest to largest entity
export const FREQUENCY_OPTIONS: {
  [key: string ]: { value: SchedulingFrequency['frequency'], label: string }
} = {
  HOURLY: {
    value: 'HOURLY',
    label: 'Hourly',
  },
  DAILY: {
    value: 'DAILY',
    label: 'Daily',
  },
  WEEKLY: {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  MONTHLY: {
    value: 'MONTHLY',
    label: 'Monthly',
  },
};

const frequencyOptions = Object.values(FREQUENCY_OPTIONS);

export const frequencies = Object.keys(FREQUENCY_OPTIONS);

const frequencyHelpText = (frequency: SchedulingFrequency['frequency']) => {
  switch (frequency) {
    case FREQUENCY_OPTIONS.HOURLY.value:
      return 'Select the minute of the hour when the report should be sent out.';
    case FREQUENCY_OPTIONS.DAILY.value:
      return 'Select the time of the day when the report should be sent out.';
    case FREQUENCY_OPTIONS.WEEKLY.value:
      return 'Select the day of the week and time of the day when the report should be sent out.';
    case FREQUENCY_OPTIONS.MONTHLY.value:
      return 'Select the day of the month and time of the day when the report should be sent out.';
    default:
      return <div />;
  }
};

const validateForm = (values: SchedulingFrequencyFormValues) => {
  let errors = {};

  if (!values.frequency) {
    errors = { frequency: 'Frequency is required' };
  }

  if (values.frequency) {
    if (!values.frequency_configuration?.time) {
      errors = set(errors, 'frequency_configuration.time', 'Time is required');
    }

    if (isWeeklyFrequency(values) && !values.frequency_configuration?.day_of_week) {
      errors = set(errors, 'frequency_configuration.day_of_week', 'Day is required');
    }

    if (isMonthlyFrequency(values) && !values.frequency_configuration?.day_of_month) {
      errors = set(errors, 'frequency_configuration.day_of_month', 'Day is required');
    }
  }

  return errors;
};

const resetConfigOnFrequencySelect = (
  newFrequency: SchedulingFrequency['frequency'],
  setFieldValue: (fieldName: string, newConfig: Partial<SchedulingFrequency['frequency_configuration']>) => void,
) => {
  const updateFrequencyConfig = (
    newFrequencyConfig: Partial<SchedulingFrequency['frequency_configuration']>,
  ) => setFieldValue('frequency_configuration', newFrequencyConfig);

  switch (newFrequency) {
    case 'DAILY':
    case 'WEEKLY':
    case 'MONTHLY':
      updateFrequencyConfig({ timezone: 'UTC' });

      return;
    default:
      updateFrequencyConfig(undefined);
  }
};

type Props = {
  schedulingFrequency?: SchedulingFrequency
  onSubmit: (newFrequency: Partial<SchedulingFrequency>) => void,
  onCancel: () => void
  title?: string,
  submitButtonText: string,
  submitLoadingText: string,
}

const SchedulingFrequencyForm = ({ schedulingFrequency, onSubmit, onCancel, title, submitButtonText, submitLoadingText }: Props) => {
  return (
    <BootstrapModalWrapper showModal
                           role="alertdialog"
                           onHide={onCancel}>
      <Formik<SchedulingFrequencyFormValues> initialValues={schedulingFrequency ?? {}}
                                             onSubmit={onSubmit}
                                             validate={validateForm}
                                             validateOnBlur={false}
                                             validateOnMount>
        {({ isSubmitting, isValidating, isValid, setFieldTouched, values, setFieldValue }) => {
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Field name="frequency">
                  {({ field: { name, value, onChange }, meta: { error, touched } }) => (
                    <Input bsStyle={(error && touched) ? 'error' : undefined}
                           help="Select how often this report should be sent out."
                           error={(error && touched) ? error : undefined}
                           id={name}
                           label="Frequency">
                      <Select onBlur={() => setFieldTouched(name, true)}
                              onChange={(newValue: SchedulingFrequency['frequency']) => {
                                if (newValue !== value) {
                                  resetConfigOnFrequencySelect(newValue, setFieldValue);
                                }

                                onChange({
                                  target: { value: newValue, name },
                                });
                              }}
                              options={frequencyOptions}
                              inputId={name}
                              placeholder="Select a frequency"
                              required
                              value={value} />
                    </Input>
                  )}
                </Field>

                {values.frequency && (
                  <>
                    <Input id="frequency-options-preview"
                           label={`${FREQUENCY_OPTIONS[values.frequency].label} frequency options`}
                           help={frequencyHelpText(values.frequency)}>
                      <FormGroup>
                        <FrequencyControls />
                      </FormGroup>
                    </Input>
                    <FormControl.Static className="text-info">
                      <Icon name="info-circle" />{' '}
                      <FrequencySummary frequencyConfiguration={values.frequency_configuration}
                                        frequency={values.frequency} />
                    </FormControl.Static>
                    <Row>
                      <FormikInput id="frequency_configuration.override_widgets_timerange"
                                   name="frequency_configuration.override_widgets_timerange"
                                   type="checkbox"
                                   label="Override widget time ranges with selected frequency" />
                      <HoverForHelp title="Override time ranges">
                        This action will override time ranges in all widgets with the selected frequency
                      </HoverForHelp>
                    </Row>
                  </>
                )}
              </Modal.Body>

              <Modal.Footer>
                <ModalSubmit onCancel={onCancel}
                             disabledSubmit={isValidating || !isValid}
                             isSubmitting={isSubmitting}
                             isAsyncSubmit
                             submitLoadingText={submitLoadingText}
                             submitButtonText={submitButtonText} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </BootstrapModalWrapper>
  );
};

SchedulingFrequencyForm.defaultProps = {
  schedulingFrequency: undefined,
  title: 'Frequency Configuration',
};

export default SchedulingFrequencyForm;
