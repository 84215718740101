import React, { useEffect } from 'react';

import ArchivePageHeaderDescription from 'archive/components/ArchivePageHeaderDescription';
import ArchiveActions from 'archive/ArchiveActions';
import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveCatalog from 'archive/components/ArchiveCatalog';
import ArchiveCreationSection from 'archive/components/ArchiveCreationSection';
import ArchiveSystemJobs from 'archive/components/ArchiveSystemJobs';
import type { LicenseStatus } from 'archive/types';
import { IndicesPropType, LicenseStatusPropType } from 'archive/propTypes';
import DocsHelper from 'util/DocsHelper';
import { IndicesActions, IndicesStore } from 'stores/indices/IndicesStore';
import type { Store } from 'stores/StoreTypes';
import type { Indices } from 'stores/indices/IndicesStore';
import connect from 'stores/connect';
import { DocumentTitle, ExternalLinkButton, PageHeader, IfPermitted } from 'components/common';
import { Button, Row, Col } from 'components/bootstrap';

import ArchivePageNavigation from './components/ArchivePageNavigation';

type Props = {
  indices: Indices,
  licenseStatus: LicenseStatus,
};

const ArchivePage = ({ indices, licenseStatus }: Props) => {
  useEffect(() => {
    ArchiveLicenseActions.getLicenseStatus();
    IndicesActions.listAll();
  }, []);

  return (
    <DocumentTitle title="Archives">
      <ArchivePageNavigation />
      <PageHeader title="Archives">
        <ArchivePageHeaderDescription />
      </PageHeader>
      <ArchiveCreationSection indices={indices} licenseStatus={licenseStatus} />
      <ArchiveSystemJobs />
      <Row className="content">
        <Col md={12}>
          <div className="pull-right">
            <IfPermitted permissions="archivecatalog:rebuild">
              <Button bsStyle="info" onClick={() => { ArchiveActions.rebuildCatalog(); }}>Rebuild Catalog</Button>
                &nbsp;
            </IfPermitted>
            <ExternalLinkButton bsStyle="info" href={DocsHelper.toString('archiving')}>
              Archive documentation
            </ExternalLinkButton>
          </div>
          <ArchiveCatalog />
        </Col>
      </Row>

    </DocumentTitle>
  );
};

ArchivePage.propTypes = {
  indices: IndicesPropType,
  licenseStatus: LicenseStatusPropType,
};

ArchivePage.defaultProps = {
  indices: {},
  licenseStatus: {
    status: undefined,
    missing: true,
    loading: true,
  },
};

export default connect(
  ArchivePage,
  {
    licenseStatus: ArchiveLicenseStore,
    indices: IndicesStore as Store<{ indices: Indices }>,
  },
  ({ indices, ...otherProps }) => ({
    indices: indices.indices,
    ...otherProps,
  }),
);
