import Reflux from 'reflux';
import URI from 'urijs';

import { qualifyUrl } from 'util/URLUtils';
import PaginationURL from 'util/PaginationURL';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import type { WidgetState } from 'views/logic/widgets/Widget';
import Widget from 'views/logic/widgets/Widget';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type {
  BackendReport,
  BackendReportWidget,
  Report,
  ParameterValues,
  ReportDelivery,
  ReportWidgetPosition,
  BackendReportParameterValues,
} from 'report/types';

import ReportsActions from './ReportsActions';

const _deserializeWidgetConfig = (widget: BackendReportWidget) => Widget
  // TODO: This needs to be fixed. Instead we should construct a type-specific widget config.
  .fromJSON(widget as unknown as WidgetState)
  .config;

const _deserializeResponse = ({
  widgets,
  parameter_values: parameterValues,
  layout,
  ...rest
}: BackendReport): Report => ({
  ...rest,
  layout: layout ? {
    pageSize: layout.page_size,
    orientation: layout.orientation,
  } : undefined,
  widgets: widgets.map((widget) => ({ ...widget, config: _deserializeWidgetConfig(widget) })),
  parameterValues: Object.entries(parameterValues)
    .map(([name, { value }]: [string, ParameterBinding]) => [name, value])
    .reduce((prev, [name, value]) => ({ ...prev, [name]: value }), {}),
});

const ReportsStore = Reflux.createStore<{}>({
  listenables: [ReportsActions],
  sourceUrl: '/plugins/org.graylog.plugins.report',
  reports: undefined,

  getInitialState() {
    return {
      reports: this.reports,
    };
  },

  getDownloadURL(reportId: string) {
    const url = new URI(this._reportingUrl(`${reportId}/generate/`));

    return url.toString();
  },

  _reportingUrl(path: string): string {
    const effectivePath = path ? `${this.sourceUrl}/reports/${path}` : `${this.sourceUrl}/reports`;

    return qualifyUrl(effectivePath);
  },

  _errorHandler(error) {
    let errorMessage;

    try {
      errorMessage = error.additional.body.message;
    } catch (e) {
      errorMessage = error.message;
    }

    return errorMessage;
  },

  listPage({ page, perPage, query }) {
    const url = PaginationURL(`${this.sourceUrl}/reports/paginated`, page, perPage, query);
    const promise = fetch('GET', qualifyUrl(url)).catch(
      (error) => {
        UserNotification.error(`Fetching reports failed with status: ${error}`, 'Could not retrieve reports');
      },
    );

    ReportsActions.listPage.promise(promise);

    return promise;
  },

  _adaptReportToRequest(report: Report, reportLogo: string, parameterValues: ParameterValues) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, parameterValues: _, positions = [], widgets, parameters, layout, ...rest } = report;

    // We omit `parameters` on purpose, since they are injected from the server when serving the response.
    return {
      logo: reportLogo,
      widgets: widgets.map(({ dashboard_id, dashboard_widget_id }) => ({ dashboard_id, dashboard_widget_id })),
      parameter_values: this._adaptParameterValuesToBindings(parameterValues),
      positions,
      layout: layout ? { page_size: layout.pageSize, orientation: layout.orientation } : null,
      ...rest,
    };
  },

  _adaptParameterValuesToBindings(parameterValues: ParameterValues): BackendReportParameterValues {
    return Object.entries(parameterValues)
      .map<[string, ParameterBinding]>(([name, value]) => [name, ParameterBinding.create('value', value)])
      .reduce((prev, [name, binding]) => ({ ...prev, [name]: binding }), {});
  },

  create(report: Report, reportLogo: string, parameterValues: ParameterValues) {
    const promise = fetch('POST', this._reportingUrl(), this._adaptReportToRequest(report, reportLogo, parameterValues));

    promise.then(
      (response) => {
        UserNotification.success('Report created successfully', `Report "${response.title}" was created successfully.`);

        return response;
      },
      (error) => {
        UserNotification.error(`Creating report "${report.title}" failed with status: ${error}`,
          'Could not save report');
      },
    );

    ReportsActions.create.promise(promise);
  },

  get(reportId: string) {
    const promise = fetch('GET', this._reportingUrl(reportId))
      .then((response: BackendReport) => _deserializeResponse(response));

    ReportsActions.get.promise(promise);
  },

  getReportLogo(reportId: string) {
    const promise = fetch('GET', this._reportingUrl(`${reportId}/logo`));

    ReportsActions.getReportLogo.promise(promise);
  },

  update(updatedReport: Report, updatedReportLogo: string, updatedParameterValues: ParameterValues) {
    const promise = fetch('PUT', this._reportingUrl(updatedReport.id), this._adaptReportToRequest(updatedReport, updatedReportLogo, updatedParameterValues));

    promise.then(
      (response) => {
        UserNotification.success(`Report "${response.title}" was updated successfully.`, 'Report updated successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Updating Report "${updatedReport.title}" contents failed with status: ${error}.`,
          'Report contents could not be updated');
      },
    );

    ReportsActions.update.promise(promise);
  },

  updateDelivery(reportId: string, updatedDelivery: ReportDelivery) {
    const promise = fetch('PUT', this._reportingUrl(`${reportId}/delivery`), {
      delivery: updatedDelivery,
    });

    promise.then(
      (response) => {
        UserNotification.success(`Report "${response.title}" delivery was updated successfully.`,
          'Report delivery updated successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Updating report ${reportId} delivery failed with status: ${error}.`,
          'Report delivery could not be updated');
      },
    );

    ReportsActions.updateDelivery.promise(promise);
  },

  updatePositions(reportId: string, updatedPositions: Array<ReportWidgetPosition>) {
    const promise = fetch('PUT', this._reportingUrl(`${reportId}/positions`), {
      positions: updatedPositions,
    }).then(
      (response) => {
        UserNotification.success(undefined, 'Report layout updated successfully');

        return _deserializeResponse(response);
      },
      (error) => {
        UserNotification.error(`Updating Report ${reportId} layout failed with status: ${error}.`,
          'Report layout could not be updated');
      },
    );

    ReportsActions.updatePositions.promise(promise);
  },

  delete(reportId: string, reportTitle: string) {
    const promise = fetch('DELETE', this._reportingUrl(`${reportId}`));

    promise.then(
      (response) => {
        UserNotification.success(`Report "${reportTitle}" was deleted successfully.`, 'Report deleted successfully');

        return response;
      },
      (error) => {
        UserNotification.error(`Deleting Report "${reportTitle}" failed with status: ${error}.`,
          'Report could not be deleted');
      },
    );

    ReportsActions.delete.promise(promise);
  },

  sendEmail(reportId: string, reportTitle: string) {
    const promise = fetch('GET', this._reportingUrl(`${reportId}/email/`));

    promise.then(
      (response) => {
        UserNotification.success('The Report is being generated and will be send to all recipients shortly, check your inbox in a few minutes.',
          'Report delivery scheduled');

        return response;
      },
      () => {
        UserNotification.error(`Sending Report "${reportTitle}" email failed. Please check your server logs for more information.`,
          'Could not send Report');
      },
    );

    ReportsActions.sendEmail.promise(promise);
  },

  getHistory(reportId: string, reportTitle: string, skip: number, limit: number) {
    const url = new URI(this._reportingUrl(`${reportId}/history/`));
    const queryParams = {
      skip: skip,
      limit: limit,
    };
    url.query(queryParams);
    const promise = fetch('GET', url.toString()).then(
      (response) => response,
      (error) => {
        UserNotification.error(`Getting Report "${reportTitle}" history failed with status: ${error}.`,
          'Could not get Report history');
      },
    );

    ReportsActions.getHistory.promise(promise);
  },

});

export default ReportsStore;
