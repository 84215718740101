import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { Input, Button, Modal } from 'components/bootstrap';
import BootstrapModalWrapper from 'components/bootstrap/BootstrapModalWrapper';
import type BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';
import ModalSubmit from 'components/common/ModalSubmit';

import type { Forwarder } from '../Types';
import { ForwardersActions } from '../stores/ForwardersStore';

type Props = {
  forwarder: Forwarder,
  buttonBsStyle?: string,
  buttonBsSize?: string,
  onSave: () => void,
};

const StyledButton = styled(Button)`
  vertical-align: top;
`;

const ForwarderForm = ({ forwarder, buttonBsStyle, buttonBsSize, onSave }: Props) => {
  const modal = useRef<BootstrapModalForm>();
  const _openModal = useCallback(() => modal.current?.open(), [modal]);
  const _closeModal = useCallback(() => modal.current?.close(), [modal]);

  const _updateForwarder = (title = forwarder.title, description = forwarder.description, inputProfileId = forwarder.input_profile_id) => {
    const updatedForwarder = {
      input_profile_id: inputProfileId,
      title,
      description,
      hostname: forwarder.hostname,
    };
    ForwardersActions.update(forwarder.id, updatedForwarder);
  };

  const handleSubmit = (values) => {
    _updateForwarder(values.title, values.description, forwarder.input_profile_id);
    onSave();
    _closeModal();
  };

  return (
    <>
      <StyledButton bsStyle={buttonBsStyle} bsSize={buttonBsSize} onClick={_openModal}>Edit</StyledButton>
      <BootstrapModalWrapper ref={modal} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update forwarder</Modal.Title>
        </Modal.Header>
        <Formik initialValues={{ title: forwarder.title, description: forwarder.description, hostname: forwarder.hostname }}
                onSubmit={handleSubmit}>
          {({
            values,
            handleChange,
            isSubmitting,
          }) => {
            return (
              <Form>
                <Modal.Body>
                  <Input id="title"
                         name="title"
                         label="Title"
                         type="text"
                         value={values.title}
                         onChange={handleChange}
                         help="Meaningful name used to identify this forwarder."
                         required />
                  <Input id="description"
                         name="description"
                         label="Description"
                         type="text"
                         value={values.description}
                         onChange={handleChange}
                         help="Description for this forwarder " />
                  <Input id="hostname"
                         name="hostname"
                         label="Hostname"
                         type="text"
                         disabled
                         value={values.hostname}
                         onChange={handleChange}
                         help="The hostname of this forwarder" />
                </Modal.Body>
                <Modal.Footer>
                  <ModalSubmit onCancel={_closeModal}
                               isSubmitting={isSubmitting}
                               isAsyncSubmit
                               submitButtonText="Update forwarder"
                               submitLoadingText="Updating forwarder..." />
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </BootstrapModalWrapper>
    </>
  );
};

ForwarderForm.propTypes = {
  forwarder: PropTypes.object,
  buttonBsStyle: PropTypes.string,
  buttonBsSize: PropTypes.string,
  onSave: PropTypes.func,
};

ForwarderForm.defaultProps = {
  forwarder: {},
  buttonBsStyle: 'info',
  buttonBsSize: null,
  onSave: () => {},
};

export default ForwarderForm;
