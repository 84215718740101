import Reflux from 'reflux';

import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import type { RefluxActions } from 'stores/StoreTypes';

import type { Backend, Pagination } from './types';

export const PAGE_SIZES = [20, 50, 100, 200, 500];
export const DEFAULT_PAGE_SIZE = 20;
export const TYPES = {
  'fs-1': 'File system',
};

type PaginatedBackendResponse = {
  backends: Array<Backend>,
  count: number,
  total: number,
  page: number,
  per_page: number,
  query: string,
};

const urlPrefix = '/plugins/org.graylog.plugins.archive';

type ArchiveBackendsActionsType = RefluxActions<{
  listBackends: (page: number, perPage: number) => Promise<PaginatedBackendResponse>,
  deleteBackend: (backend: Backend, deleteArchives: boolean) => Promise<any>,
  getBackend: (backendId: string) => Promise<Backend>,
  saveBackend: (backend: Backend) => Promise<Backend>,
}>;

export const ArchiveBackendsActions: ArchiveBackendsActionsType = Reflux.createActions({
  listBackends: { asyncResult: true },
  deleteBackend: { asyncResult: true },
  getBackend: { asyncResult: true },
  saveBackend: { asyncResult: true },
});

export type ArchiveBackendsStoreState = {
  backends: {
    backends: Array<Backend>;
    pagination: Pagination;
  };
  backend?: Backend;
};

const ArchiveBackendsStore = Reflux.createStore({
  listenables: [ArchiveBackendsActions],

  backends: {
    backends: [],
    pagination: {
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      total: 0,
      count: 0,
      query: '',
    },
  },
  backend: undefined,

  init() {
    this.backendPagination = this.backends.pagination;
  },

  getInitialState() {
    return {
      backends: this.backends,
      backend: this.backend,
    };
  },

  _errorHandler(message: string, title: string, cb: (error: any) => void) {
    return (error) => {
      const errorMessage = error.additional?.body?.message ?? error.message ?? 'Some fields are not valid.';
      UserNotification.error(`${message}: ${errorMessage}`, title);

      if (cb) {
        cb(error);
      }
    };
  },

  _configUrl() {
    return qualifyUrl(`${urlPrefix}/config`);
  },

  _archivesUrl(path: string) {
    return qualifyUrl(`${urlPrefix}/cluster/archives${path}`);
  },

  _url(path: string) {
    return qualifyUrl(`${urlPrefix}${path}`);
  },

  listBackends(page: number, perPage: number) {
    const url = this._url(`/backends?page=${page}&per_page=${perPage}`);
    const promise = fetch('GET', url);

    promise.then((response: PaginatedBackendResponse) => {
      const pagination = {
        count: response.count,
        total: response.total,
        page: response.page,
        per_page: response.per_page,
        query: response.query,
      };
      this.backendPagination = pagination;
      this.trigger({ backends: { backends: response.backends, pagination: pagination } });
    }, this._errorHandler('Fetching archive backends failed', 'Could not retrieve archive backends'));

    ArchiveBackendsActions.listBackends.promise(promise);
  },

  deleteBackend(backend: Backend, deleteArchives: boolean = false) {
    if (!backend.id) {
      // eslint-disable-next-line no-console
      console.log('Tried to delete a backend without id, this should not happen.');
    } else {
      const promise = fetch('DELETE', this._url(`/backends/${backend.id}?delete_archives=${String(deleteArchives)}`));

      promise.then(() => {
        UserNotification.success(`Backend "${backend.title}" deleted`);
        this.listBackends(this.backendPagination.page, this.backendPagination.per_page);
      }, this._errorHandler('Backend deletion failed', `Could not delete backend: ${backend.title}`));

      ArchiveBackendsActions.deleteBackend.promise(promise);
    }
  },

  getBackend(backendId: string) {
    const url = this._url(`/backends/${backendId}`);
    const promise = fetch('GET', url);

    promise.then((response: Backend) => {
      this.trigger({ backend: response });
    }, this._errorHandler('Fetching archive backend failed', 'Could not retrieve archive backend'));

    ArchiveBackendsActions.getBackend.promise(promise);
  },

  saveBackend(backend: Backend) {
    let promise;

    if (backend.id) {
      promise = fetch('PUT', this._url(`/backends/${backend.id}`), backend);
    } else {
      promise = fetch('POST', this._url('/backends'), backend);
    }

    promise.then((response) => {
      this.trigger({ backend: response });
      UserNotification.success('Archive backend was saved successfully');
    }, this._errorHandler('Saving archive backend failed', 'Unable to save archive backend'));

    ArchiveBackendsActions.saveBackend.promise(promise);
  },

});

export default ArchiveBackendsStore;
