import * as React from 'react';
import { useMemo, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import UserDateTimeProvider from 'contexts/UserDateTimeProvider';
import { Col, Row } from 'components/bootstrap';
import withParams from 'routing/withParams';
import type { Report, ReportLayout } from 'report/types';
import ReportWidgets from 'report/report-render-page/ReportWidgets';
import { REPORT_TZ_FALLBACK } from 'report/Constants';
import useReportAndLogo from 'report/hooks/useReportAndLogo';
import ErrorBoundary from 'report/common/ErrorBoundary';
import ReportRenderErrorPage from 'report/report-render-page/ReportRenderErrorPage';
import useQuery from 'routing/useQuery';
import type { TimeRangeQueryParameter } from 'views/logic/TimeRange';
import { timeRangeFromQueryParameter } from 'views/logic/TimeRange';

import ReportParameters from './ReportParameters';

const GlobalStyles = createGlobalStyle<ReportLayout>`
  html body {
    margin-top: 0;
    font-family: serif;
    font-size: 18px;
  }
  
  #footer {
    display: none;
  }

  @media print {
    @page {
      margin: 1in;
    }
  }
`;

const Container = styled.div`
  width: 210mm;
`;

const CoverPage = styled.div`
  padding-top: 30%;

  h1 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 0.8em;
  }

  h2 {
    color: #555 !important;
    font-size: 1.6em;
    text-align: center;
  }
`;

const Logo = styled.div`
  margin-left: auto;
  margin-top: 100px;
  padding: 10px;
  text-align: center;

  img {
    max-height: 150px;
    max-width: 150px;
  }
`;

export const Description = styled.p`
  white-space: pre-wrap;
`;

const PageBreak = styled.div`
  page-break-after: always;
`;

const InvisibleDiv = styled.div`
  display: none;
`;

type Props = {
  params: {
    reportId: string,
  }
}

const useUpdateRenderComplete = (widgets: Report['widgets'] = []) => {
  const [renderedWidgets, setRenderedWidgets] = useState<Array<string>>([]);

  const onWidgetRenderComplete = (widgetId: string) => () => {
    if (!renderedWidgets.includes(widgetId)) {
      setRenderedWidgets((prevWidgets) => ([...prevWidgets, widgetId]));
    }
  };

  return {
    renderComplete: widgets?.length === 0 || widgets.length === renderedWidgets.length,
    onWidgetRenderComplete,
  };
};

type RenderedReportProps = {
  reportId: string,
};

type ReportRenderPageQuery = {} | TimeRangeQueryParameter;

const extractTimeRangeOverride = (query: ReportRenderPageQuery) => ('rangetype' in query
  ? timeRangeFromQueryParameter(query)
  : undefined);

const RenderedReport = ({ reportId }: RenderedReportProps) => {
  const query = useQuery();
  const timerangeOverride = useMemo(() => extractTimeRangeOverride(query), [query]);
  const { report, reportLogo, error } = useReportAndLogo(reportId);
  const { onWidgetRenderComplete, renderComplete } = useUpdateRenderComplete(report?.widgets);

  if (error) {
    return <ReportRenderErrorPage error={error} />;
  }

  if (!report) {
    return null;
  }

  const reportTz = report.timezone ?? REPORT_TZ_FALLBACK;

  return (
    <UserDateTimeProvider tz={reportTz}>
      <GlobalStyles />
      <Row>
        <Col md={12}>
          <Container>
            <CoverPage>
              <h1>{report.title}</h1>
              <h2 className="has-bm">{report.subtitle}</h2>
              <p className="text-center">All times are displayed in the time zone {reportTz}.</p>
              {reportLogo && <Logo><img src={reportLogo} alt="report-logo" /></Logo>}
            </CoverPage>
            <PageBreak />
            <Description>{report.description}</Description>
            {report.parameterValues && (
              <ReportParameters parameters={report.parameters}
                                parameterValues={report.parameterValues} />
            )}
            <ReportWidgets widgets={report.widgets}
                           positions={report.positions}
                           parameterValues={report.parameterValues}
                           onWidgetRenderComplete={onWidgetRenderComplete}
                           timerangeOverride={timerangeOverride} />

            {renderComplete && <InvisibleDiv id="render-complete" />}
          </Container>
        </Col>
      </Row>
    </UserDateTimeProvider>
  );
};

const ReportRenderPage = ({ params: { reportId } }: Props) => (
  <ErrorBoundary FallbackComponent={ReportRenderErrorPage}>
    <RenderedReport reportId={reportId} />
  </ErrorBoundary>
);

export default withParams(ReportRenderPage);
