import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import {
  NavLink,
} from 'react-router-dom';

import securityContentThemeVariables from 'security-app/theme/constants';

const StyledLink = styled(NavLink)(({ theme }: { theme:DefaultTheme }) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${theme.colors.global.navigationBackground};
  transition: all 0.33s ease-in-out;
  padding-left: 27px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E8E8E8;
  color: ${theme.colors.gray[30]};
  &:hover {
    background: ${securityContentThemeVariables.colors.primaryDull};
    text-decoration: none;
    color: ${theme.colors.gray[30]};
  }

  &.active {
    background: ${securityContentThemeVariables.colors.primaryDull};
    color: ${theme.colors.gray[30]};
  }

  svg {
    margin-right: 40px;
    height: 20px;
    width: 20px;
  }
`);

export default StyledLink;
