import * as React from 'react';
import * as Immutable from 'immutable';
import type { $PropertyType } from 'utility-types';

import type User from 'logic/users/User';
import type { Pagination } from 'stores/PaginationTypes';

import TeamsAssignment from '../teams-assignment/TeamsAssignment';
import type Team from '../../logic/Team';
import TeamsDomain from '../../domainActions/TeamsDomain';

type Props = {
  user: User,
  readOnly: boolean,
};

const UserTeamsAssignment = ({ user, readOnly }: Props) => {
  const _getUnassignedTeams = (teams: Immutable.List<Team>) => {
    return teams.filter((t) => (!t.users.includes(user.id) && !t.isSynced)).toList();
  };

  const _loadAssignedTeams = (pagination: Pagination) => TeamsDomain.loadTeamsForUser(user.id, pagination);
  const _unassignTeam = (teamId: $PropertyType<Team, 'id'>) => TeamsDomain.removeMember(teamId, user.id);
  const _assignTeams = (teamIds: Immutable.Set<$PropertyType<Team, 'id'>>) => TeamsDomain.addMemberToTeams(user.id, teamIds);
  const _buildTeamWithEntity = (team: Team) => team.toBuilder().users(Immutable.Set([user.id])).build();

  return (
    <TeamsAssignment getUnassignedTeams={_getUnassignedTeams}
                     readOnly={readOnly}
                     loadAssignedTeams={_loadAssignedTeams}
                     unassignTeam={_unassignTeam}
                     buildTeamWithEntity={_buildTeamWithEntity}
                     assignTeams={_assignTeams} />
  );
};

export default UserTeamsAssignment;
